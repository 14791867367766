import React from "react";
import {
  Card,
  Icon,
  Checkbox,
  Tooltip,
  Button,
  Avatar,
  message,
  Dropdown,
  Menu,
  Modal,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as PaymentStatusIcon } from "../../../../../assets/images/icons/payment_status_icon.svg";
import { ReactComponent as PaymentStatusIconglobal } from "../../../../../assets/images/icons/payment_status_iconglobal.svg";
import { ReactComponent as ShipmentStatusIcon } from "../../../../../assets/images/icons/shopping-cart.svg";
import { ReactComponent as CircleCross } from "../../../../../assets/images/icons/circle-cross.svg";
import { ReactComponent as NotFulfillIcon } from "../../../../../assets/images/icons/not-fulfill-icon.svg";
import { hasPermission } from "../../../../../Helpers/Permissions";
import LineItems from "./GlobalContant/LineItems";
import CustomerInfo from "./GlobalContant/CustomerInfo";
import Shipment from "./GlobalContant/Shipment";
import Notes from "./GlobalContant/Notes";
import Logs from "./GlobalContant/Logs";
import RepetitiveOrders from "./GlobalContant/RepetitiveOrders";
import VfIdCopyBadge from "../../../Common/VfIdCopyBadge";
import moment from "moment";
import axios from "axios";
import "./index.scss";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";
import ActionNotification from "../../../Common/ActionNotification";
import { SHOPIFY_NAME } from "../../../../../constants";
const { confirm } = Modal;

const tagColorArr = [
  "magenta",
  "red",
  "volcano",

  "gold",
  "lime",

  "cyan",
  "blue",
  "geekblue",
  "purple",
];

class OrderCardNew extends React.Component {
  state = {
    order: this.props.order,
    activeTab:
      this.props.pageType === "trackorders" || this.props.pageType === "ndr"
        ? "shipments"
        : "lineItems",
    customerTab: {
      editing: false,
      saving: false,
      showHideheaderdata: false,
      showHideheaderdata2: false,
    },
    selectedLineItems: [],
    errors: {},
    shipmentsVisible: {},
    cancelVerifiedOrder: {
      cancelOnShopify: false,
      loading: false,
    },
    lbhEscalationLoading: false,
    editabledisable: false,
    selectedUnserviceable: [],
    notLinked: undefined,
    // remarkProvisional: null,
  };

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  notLinkedChecker() {
    let lineItems = this.state.order.line_items;
    let notLinked = false;

    lineItems.forEach((e) => {
      if (e.item_vforderstatus === "notlinked") {
        notLinked = true;
      }
    });

    this.setState({ notLinked });
  }

  componentDidMount() {
    this.validateCustomerDetails();
    this.notLinkedChecker();
  }

  clearError() {
    this.setState({ errors: {} });
  }

  getFilteredLists() {
    let lineItems = this.state.order.line_items;
    return lineItems;
  }

  orderactionstatusican() {
    let lineItems = this.state.order.line_items;
    let ordersactionshipmentstatusican = lineItems.map((li) => {
      return li.item_shipmentstatus;
    });
    return ordersactionshipmentstatusican;
  }

  orderactionstatusican2() {
    let lineItems = this.state.order.line_items;
    let ordersactionvforderstatusican = lineItems.map((li) => {
      return li.item_vforderstatus;
    });
    return ordersactionvforderstatusican;
  }

  validateCustomerDetails() {
    const order = this.state.order;
    let errors = {};
    let phone = order.shipping_address.phone;
    let name = order.shipping_address.name;

    if (!/^[A-Za-z.\s]*$/.test(name)) {
      errors.customer_details_name = "Invalid Name";
    }

    if (phone === null) {
      errors.shipping_address_phone = "This is a required field";
    }

    if (!/^[0-9+\s]+$/.test(phone)) {
      errors.shipping_address_phone = "Invalid Mobile Number";
    }

    if (order.customer_details.email && order.customer_details.email.length) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          order.customer_details.email
        )
      ) {
        errors.customer_details_email = "Invalid Email";
      }
    }

    if (phone !== null && phone.toString().length < 10) {
      errors.shipping_address_phone = "Enter atleast 10 digit number!";
    }
    if (phone !== null && phone.toString().length > 13) {
      errors.shipping_address_phone = "Number cannot exceed 13 digits!";
    }

    if (!order.shipping_address.name || order.shipping_address.name === "") {
      errors.customer_details_name = "This is a required field!";
    }
    if (
      !order.shipping_address.address1 ||
      order.shipping_address.address1 === ""
    ) {
      errors.shipping_address_address1 = "This is a required field!";
    }

    if (
      !!order.shipping_address &&
      !!order.shipping_address.address1 &&
      order.shipping_address.address1.length > 200
    ) {
      errors.shipping_address_address1 = "Max limit upto 200 characters!";
    }
    if (
      !!order.shipping_address &&
      !!order.shipping_address.address2 &&
      order.shipping_address.address2.length > 200
    ) {
      errors.shipping_address_address2 = "Max limit upto 200 characters!";
    }

    if (
      !order.shipping_address.address1.length &&
      !/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(
        order.shipping_address.address1
      )
    ) {
      errors.shipping_address_address1 = "Incorrect Address language";
    }

    if (!order.shipping_address.zip || order.shipping_address.zip === "") {
      errors.shipping_address_zip = "This is a required field!";
    }
    if (
      !order.shipping_address.country ||
      order.shipping_address.country === ""
    ) {
      errors.shipping_address_country = "This is a required field!";
    }
    if (!order.shipping_address.city || order.shipping_address.city === "") {
      errors.shipping_address_city = "This is a required field!";
    }
    if (
      !order.shipping_address.province ||
      order.shipping_address.province === ""
    ) {
      errors.shipping_address_province = "This is a required field!";
    }

    // if (!order.shipping_address.phone || order.shipping_address.phone === "") {
    //   errors.shipping_address_phone = "This is a required field!";
    // }

    if (!/^[0-9+\s]+$/.test(phone)) {
      errors.shipping_address_phone = "Please enter a valid phone number!";
    }

    if (
      !/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(
        order.shipping_address.address1
      )
    ) {
      errors.shipping_address_address1 = "Incorrect Address language";
    }

    if (
      order.shipping_address.address2 &&
      order.shipping_address.address2.length
    ) {
      if (
        !/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(
          order.shipping_address.address2
        )
      ) {
        errors.shipping_address_address2 = "Incorrect Address language";
      }
    }

    if (!!/^[A-Za-z.\s]*$/.test(name)) {
      if (!/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(name)) {
        errors.customer_details_name = "Invalid Name";
      }
    }

    this.setState({ errors: errors });
    return errors;
  }

  changeLineItemQty(id, qty) {
    this.setState(
      {
        order: {
          ...this.state.order,
          line_items: this.state.order.line_items.map((li) => {
            if (li.lineitem_id === id) {
              return {
                ...li,
                quantity: Math.max(1, Math.min(li.original_qty, qty)),
              };
            } else {
              return li;
            }
          }),
        },
      },
      () => this.getSelectedItems()
    );
  }

  componentDidUpdate(pp) {
    if (pp.selectAllOrders !== this.props.selectAllOrders) {
      this.selectAllNone(this.props.selectAllOrders);
    }
  }

  getUnserviceableItems(cb = undefined) {
    const selectedItems = {};

    this.state.order.line_items
      .filter((li) => {
        return (
          this.state.selectedUnserviceable.findIndex(
            (sli) => sli === li.lineitem_id
          ) !== -1
        );
      })
      .forEach(
        (li) =>
          (selectedItems[li.lineitem_id] = {
            ...li,
            _id: this.state.order._id["$oid"],
          })
      );

    this.props.onSelectChange(
      this.state.order.order_shopify.name,
      selectedItems,
      cb
    );
  }

  getSelectedItems(cb = undefined) {
    const selectedItems = {};
    this.state.order.line_items
      .filter((li) => {
        return (
          this.state.selectedLineItems.findIndex(
            (sli) => sli === li.lineitem_id
          ) !== -1
        );
      })
      .forEach(
        (li) =>
          (selectedItems[li.lineitem_id] = {
            ...li,
            _id: this.state.order._id["$oid"],
          })
      );
    this.props.onSelectChange(
      this.state.order.order_shopify.name,
      selectedItems,
      cb
    );
  }

  onAddNote(note) {
    this.setState({
      order: {
        ...this.state.order,
        order_notes: [
          note,
          ...(this.state.order.order_notes ? this.state.order.order_notes : []),
        ],
      },
    });
  }

  lineItemSelectedChange(id) {
    if (this.isProvisional()) {
      return;
    }
    let selectedLineItems = [...this.state.selectedLineItems];
    const findIndex = selectedLineItems.findIndex((li) => li === id);
    if (findIndex === -1) {
      selectedLineItems.push(id);
    } else {
      selectedLineItems = [
        ...selectedLineItems.slice(0, findIndex),
        ...selectedLineItems.slice(findIndex + 1),
      ];
    }
    this.setState(
      {
        selectedLineItems,
      },
      () => this.getSelectedItems()
    );
  }

  toggleLineItemEditable(id) {
    const that = this;
    const currentItem = this.state.order.line_items.find(
      (li) => li.lineitem_id === id
    );
    if (
      currentItem.quantity < 1 ||
      currentItem.quantity > currentItem.original_qty
    ) {
      message.error(
        `Item quantity must be between 1 & ${currentItem.original_qty}`
      );
      return;
    }
    this.setState(
      {
        order: {
          ...this.state.order,
          line_items: this.state.order.line_items.map((li) => {
            if (li.lineitem_id === id) {
              if (
                that.state.selectedLineItems.findIndex((li) => li === id) ===
                  -1 &&
                li.editable
              ) {
                that.lineItemSelectedChange(id);
              }
              return {
                ...li,
                editable: !li.editable,
              };
            } else {
              return li;
            }
          }),
        },
      },
      this.setState({ editabledisable: !this.state.editabledisable })
    );
  }

  isProvisional() {
    let provisional = false;
    this.state.order.line_items.forEach((li) => {
      if (
        !provisional &&
        li.provisional &&
        li.vfsku !== undefined &&
        (li.item_vforderstatus === "open" ||
          li.item_vforderstatus === "verified" ||
          li.item_vforderstatus === "calling_inprogress")
      ) {
        provisional = true;
      }
    });
    return provisional;
  }

  isProvisional2() {
    let provisional2 = false;
    this.state.order.line_items.forEach((li) => {
      if (li.provisional === undefined) {
        return (provisional2 = true);
      }
      if (
        !provisional2 &&
        li.provisional &&
        li.vfsku === undefined &&
        (li.item_vforderstatus === "open" ||
          li.item_vforderstatus === "verified" ||
          li.item_vforderstatus === "calling_inprogress")
      ) {
        provisional2 = true;
      }
    });
    return provisional2;
  }

  // unselect unservicable order

  selectAllNone(checked, cb = undefined) {
    let selectedLineItems = [];
    if (checked) {
      selectedLineItems = this.getFilteredLists()
        .filter(
          (li) =>
            li.item_vforderstatus !== "cancelled" &&
            // li.item_vforderstatus !== "notlinked" &&
            li.item_vforderstatus !== "verified_ordered" &&
            !!this.state.order.is_servicable
        )
        .map((li) => li.lineitem_id);
    }
    if (this.isProvisional()) {
      selectedLineItems = [];
    }

    if (
      (this.props.order.place_status &&
        this.props.order.place_status === "lock") ||
      Object.keys(this.state.errors).length > 0
    ) {
      selectedLineItems = [];
    }

    this.setState(
      {
        selectedLineItems,
      },
      () => {
        this.getSelectedItems(cb);
      }
    );
  }

  // unselect unservicable order

  placeUnservicabe(cb = undefined) {
    let selectedLineItems = [];
    selectedLineItems = this.getFilteredLists()
      .filter(
        (li) =>
          li.item_vforderstatus !== "cancelled" &&
          // li.item_vforderstatus !== "notlinked" &&
          li.item_vforderstatus !== "verified_ordered" &&
          !this.state.order.is_servicable
      )
      .map((li) => li.lineitem_id);
    this.setState({ selectedUnserviceable: selectedLineItems }, () => {
      this.getUnserviceableItems(cb);
    });
  }

  selectAllAndConfirm(key, id, order) {
    this.setState({ showHideheaderdata: false }, () =>
      !!this.state.order.is_servicable
        ? this.selectAllNone("verifyorder", () =>
            this.props.toggleConfirmationModal(key, id, order)
          )
        : this.placeUnservicabe(() =>
            this.props.toggleConfirmationModal(key, id, order)
          )
    );
  }

  setShopifyOrderCancel(checked) {
    this.setState({
      cancelVerifiedOrder: {
        ...this.state.cancelVerifiedOrder,
        cancelOnShopify: checked,
      },
    });
  }

  checkVerifiedCancel() {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.REACT_APP_API_URL + "/orders/placed_order_status",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          orderid: this.state.order._id["$oid"],
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            var msg = "";
            if (res.data.data.fee > 0) {
              msg =
                "You will be charged a cancellation fee of INR " +
                res.data.data.fee +
                ".";
            }

            this.cancelVerifiedOrder(msg);
          } else {
            message.error(res.data.msg);
          }
          resolve();
        })
        .catch((e) => reject(e));
    });
  }

  cancelVerifiedOrder(cancmsg) {
    confirm({
      title: "Cancel Order?",

      content: (
        <div className="cancle-order" style={{ marginLeft: "-38px" }}>
          Are you sure, you want to cancel this order?
          <br />
          <br />
          {cancmsg.length === 0 ? null : (
            <p
              style={{
                background: "#FDF7F0",
                padding: "8px 8px",
                border: "1px solid #FF9200",
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
                lineHeight: "16px",
                fontSize: "12px",
              }}
            >
              <span style={{ marginRight: "8px" }}>
                <svg
                  id="exclamation-circle"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Shape"
                    d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,0,1,20.486,20.486,11.922,11.922,0,0,1,12,24Zm0-7.5a1.055,1.055,0,1,0,1.055,1.055A1.056,1.056,0,0,0,12,16.5ZM12,4.477a.938.938,0,0,0-.937.938v8.648a.937.937,0,1,0,1.875,0V5.414A.939.939,0,0,0,12,4.477Z"
                    fill="#ff9500"
                  />
                </svg>
              </span>
              <span style={{ margin: "-4px 0 0 0" }}>{cancmsg}</span>
            </p>
          )}
          <br />
          <Checkbox
            defaultChecked={false}
            onChange={(e) => this.setShopifyOrderCancel(e.target.checked)}
          >
            {`Cancel order on ${SHOPIFY_NAME}`}
          </Checkbox>
        </div>
      ),
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState(
            {
              cancelVerifiedOrder: {
                ...this.state.cancelVerifiedOrder,
                loading: true,
              },
            },
            () => {
              axios({
                url:
                  process.env.REACT_APP_API_URL +
                  "/orders/cancel_verified_order",
                method: "post",
                data: {
                  storeid: this.props.match.params.storeid,
                  orderid: this.state.order._id["$oid"],
                  cancelOnShopify: this.state.cancelVerifiedOrder
                    .cancelOnShopify,
                },
                withCredentials: true,
              })
                .then((res) => {
                  if (res.data.success === 1) {
                    ActionNotification({
                      notificationType: "cancel",
                      shopifyId: this.state.order.order_shopify.id,
                      orderId: this.state.order.order_vfid,
                      showShopifyId: this.state.order.order_shopify.name,
                    });
                    this.props.reloadData();
                  } else {
                    message.error(res.data.msg);
                    this.setShopifyOrderCancel(false);
                  }
                  resolve();
                })
                .catch((e) => reject(e));
            }
          );
        }).catch((e) => {
          message.error(e.message);
        });
      },
      keyboard: false,
      maskClosable: false,
      onCancel: () => {
        if (!this.state.cancelVerifiedOrder.loading) {
          this.setShopifyOrderCancel(false);
        }
      },
      destroyOnClose: true,
      okText: "Yes, Cancel",
      cancelText: "No",
    });
  }

  rtoRiskChecker(score, error) {
    if (error) {
      try {
        if (score < 50) {
          return <p className="rto-badge custom-pos high"> High RTO Risk </p>;
        }

        if (score >= 50 && score < 70) {
          return (
            <p className="rto-badge custom-pos moderate"> Moderate RTO Risk </p>
          );
        }

        if (score >= 70) {
          return <p className="rto-badge custom-pos no"> Low RTO Risk </p>;
        }
      } catch (error) {}
    } else {
      try {
        if (score < 50) {
          return <p className="rto-badge high"> High RTO Risk </p>;
        }

        if (score >= 50 && score < 70) {
          return <p className="rto-badge moderate"> Moderate RTO Risk </p>;
        }

        if (score >= 70) {
          return <p className="rto-badge no"> Low RTO Risk </p>;
        }
      } catch (error) {}
    }
  }

  render() {
    const {
      paymentStatusText,
      shipmentStatusColors,
      shipmentStatusText,
      vfOrderStatusColors,
      vfOrderStatusText,
      vfOrderStatusIcons,
    } = this.props;

    const disableunlinkded =
      (this.props.order.place_status &&
        this.props.order.place_status === "lock") ||
      Object.keys(this.state.errors).length > 0;
    console.log(
      this.props.order.place_status,
      (this.props.order.place_status &&
        this.props.order.place_status === "lock") ||
        Object.keys(this.state.errors).length > 0,
      disableunlinkded
    );
    const order = this.state.order;
    const notes = (order.order_notes || []).filter(
      (n) => n.note_type === "note"
    );
    const logs = (order.order_notes || []).filter((n) => n.note_type === "log");

    const repitiveOrders = (order && order.repeated_orders) || [];

    let lineItems = this.getFilteredLists();
    let orderactionstatusican = this.orderactionstatusican();
    let orderactionstatusican2 = this.orderactionstatusican2();

    if (lineItems.length === 0) {
      return <></>;
    }

    // let geteditablekeydisable = this.geteditablekeydisable();

    const provisional = this.isProvisional();
    const provisional2 = this.isProvisional2();
    //  const remarkProvisional = this.remarkProvisional();

    let serviceable = true;
    if (order.is_servicable === 0) {
      serviceable = false;
    }

    let isverifychecked = true;
    if (order.is_servicable === 0 && order.is_cod === 1) {
      isverifychecked = false;
    }
    let allSame = true;

    let allSameStatus = lineItems.length ? lineItems[0].item_vforderstatus : "";

    let allUnlinked = true;

    let hasAtleastOneOpen = false;

    lineItems.forEach((li, idx) => {
      if (
        //befor
        // li.item_vforderstatus === "open" ||
        // li.item_vforderstatus === "verified" ||
        // li.item_vforderstatus === "not_fulfill"
        //afer  discuss
        li.item_vforderstatus === "open" ||
        li.item_vforderstatus === "verified" ||
        li.item_vforderstatus === "calling_inprogress"
      ) {
        hasAtleastOneOpen = true;
      }

      if (idx > 0 && allSame) {
        if (allSameStatus !== li.item_vforderstatus) {
          allSame = false;
        }
      }

      lineItems.forEach((li) => {
        if (li.provisional === undefined) {
          return (allUnlinked = true);
        }
        if (li.item_vforderstatus !== "notlinked") {
          allUnlinked = false;
        }
      });
    });

    let orderOverallStatus = (
      <span
        style={{ color: shipmentStatusColors["processing"], fontSize: "12px" }}
      >
        {shipmentStatusText["processing"]}
      </span>
    );

    let cancellable = false;

    if (allSame && allSameStatus !== "verified_ordered") {
      orderOverallStatus = (
        <span
          style={{
            color: vfOrderStatusColors[allSameStatus],
            fontSize: "12px",
          }}
        >
          {vfOrderStatusText[allSameStatus] === "On Hold" ? (
            <Button
              className="clickable"
              onClick={({ key }) =>
                this.selectAllAndConfirm(key, order.order_shopify.name)
              }
            >
              Unhold Order
            </Button>
          ) : (
            <span
              style={{
                fontSize: "12px",
                verticalAlign: "baseline",
              }}
            >
              <Icon
                component={
                  // shipmentStatusText[orderactionstatusican] === "Delivered"
                  //   ? ShipmentStatusIcon
                  //   : shipmentStatusText[orderactionstatusican] ===
                  //       "Returned" ||
                  //     shipmentStatusText[orderactionstatusican] === "In Transit"
                  //   ? ShipmentStatusIcon
                  //   : vfOrderStatusIcons[orderactionstatusican2]

                  vfOrderStatusText[orderactionstatusican2[0]] ===
                  "Cancelled Order"
                    ? CircleCross
                    : vfOrderStatusText[orderactionstatusican2[0]] ===
                      "Do Not Fulfill"
                    ? NotFulfillIcon
                    : null
                }
                style={{
                  color:
                    orderactionstatusican === "manifested"
                      ? "#D241FD"
                      : shipmentStatusText[orderactionstatusican] ===
                        "Delivered"
                      ? "#73D13D"
                      : shipmentStatusText[orderactionstatusican] === "Returned"
                      ? "#F12F1A"
                      : shipmentStatusText[orderactionstatusican] ===
                        "In Transit"
                      ? "#FEC62D"
                      : vfOrderStatusColors[orderactionstatusican2],

                  fontSize: 17,
                  marginRight: "6px",
                }}
              />
              {vfOrderStatusText[allSameStatus]}
            </span>
          )}
        </span>
      );
    } else {
      const statusPriorities = {
        manifested: 0,
        processing: 1,
        transit: 2,
        rto_in_transit: 3,
        delivered: 4,
        rto: 5,
      };
      let currentDefaultStatus = 0;
      lineItems.forEach((li) => {
        if (
          currentDefaultStatus < statusPriorities[li["item_shipmentstatus"]]
        ) {
          currentDefaultStatus = statusPriorities[li["item_shipmentstatus"]];
        }
      });

      if (this.props.pageType === "processingorders") {
        cancellable = true;
      } else if (currentDefaultStatus <= 1) {
        cancellable = false;
      }

      currentDefaultStatus = Object.keys(statusPriorities).find(
        (k) => currentDefaultStatus === statusPriorities[k]
      );
      orderOverallStatus = (
        <span
          style={{
            color: shipmentStatusColors[currentDefaultStatus],
            fontSize: "12px",
          }}
        >
          <Icon
            component={
              shipmentStatusText[orderactionstatusican[0]] === "Delivered"
                ? ShipmentStatusIcon
                : shipmentStatusText[orderactionstatusican[0]] === "Returned" ||
                  shipmentStatusText[orderactionstatusican[0]] ===
                    "In Transit" ||
                  shipmentStatusText[orderactionstatusican[0]] ===
                    "RTO In-Transit"
                ? ShipmentStatusIcon
                : vfOrderStatusIcons[orderactionstatusican2]
            }
            style={{
              color:
                shipmentStatusText[orderactionstatusican[0]] ===
                "RTO In-Transit"
                  ? "#af8100"
                  : shipmentStatusText[orderactionstatusican[0]] === "Delivered"
                  ? "#73D13D"
                  : shipmentStatusText[orderactionstatusican[0]] === "Returned"
                  ? "#F12F1A"
                  : shipmentStatusText[orderactionstatusican[0]] ===
                    "In Transit"
                  ? "#FEC62D"
                  : vfOrderStatusColors[orderactionstatusican2],

              fontSize: 17,
              marginRight: "6px",
            }}
          />
          {shipmentStatusText[currentDefaultStatus]}
        </span>
      );

      if (
        shipmentStatusText[currentDefaultStatus] === "In Processing" &&
        this.props.pageType === "allorders"
      ) {
        cancellable = true;
      }
    }

    return (
      <Card
        id="fade-in"
        className={`oreder_card_new ${
          provisional || Object.keys(this.state.errors).length > 0
            ? "provisional"
            : ""
        }`}
      >
        {this.props.pageType === "openorders" && !!this.props.showMavFilters
          ? this.rtoRiskChecker(
              this.state.order.score,
              Object.keys(this.state.errors).length > 0 ? true : false
            )
          : null}

        <div className="vforderid_tag">
          <span className="vf_order">
            {" "}
            <VfIdCopyBadge
              text={"VF Order"}
              hashSign={true}
              textToCopy={order.order_vfid}
            />
            {/* {order.order_vfid} */}
          </span>

          {!serviceable ? (
            <span className="not_serviceable">
              <span style={{ marginRight: "4px", marginTop: "4px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                >
                  <g
                    id="Group_3314"
                    data-name="Group 3314"
                    transform="translate(-1856.5 -1108.5)"
                  >
                    <circle
                      id="Ellipse_45"
                      data-name="Ellipse 45"
                      cx="8"
                      cy="8"
                      r="8"
                      transform="translate(1857 1109)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1"
                    />
                    <g
                      id="Group_3305"
                      data-name="Group 3305"
                      transform="translate(1193 -183)"
                    >
                      <g
                        id="Group_3304"
                        data-name="Group 3304"
                        transform="translate(665.566 1296.006)"
                      >
                        <path
                          id="Path_64"
                          data-name="Path 64"
                          d="M323.612,304.4a1.461,1.461,0,1,0,1.462,1.463A1.461,1.461,0,0,0,323.612,304.4Zm0,2.191a.731.731,0,1,1,.731-.731.731.731,0,0,1-.731.731Z"
                          transform="translate(-314.45 -299.322)"
                          fill="#fff"
                        />
                        <path
                          id="Path_65"
                          data-name="Path 65"
                          d="M103.108,304.4a1.461,1.461,0,1,0,1.461,1.461A1.461,1.461,0,0,0,103.108,304.4Zm0,2.191a.731.731,0,1,1,.731-.731.731.731,0,0,1-.731.731Z"
                          transform="translate(-99.237 -299.322)"
                          fill="#fff"
                        />
                        <path
                          id="Path_66"
                          data-name="Path 66"
                          d="M333.006,110.991a.365.365,0,0,0-.326-.2h-1.924v.729h1.7l.995,1.978.653-.328Z"
                          transform="translate(-322.859 -110.059)"
                          fill="#fff"
                        />
                        <path
                          id="Path_67"
                          data-name="Path 67"
                          d="M11.084,16.949h2.763v.731H11.084Z"
                          transform="translate(-5.963 -10.762)"
                          fill="#fff"
                        />
                        <path
                          id="Path_68"
                          data-name="Path 68"
                          d="M47.346,353.345H46.08a.365.365,0,1,0,0,.731h1.266a.365.365,0,0,0,0-.731Z"
                          transform="translate(-44.68 -347.158)"
                          fill="#fff"
                        />
                        <path
                          id="Path_69"
                          data-name="Path 69"
                          d="M56.6,82.49l-.748-.922a.385.385,0,0,0-.3-.14H52.8V78.885a.372.372,0,0,0-.379-.364H46.1a.364.364,0,1,0,0,.727h5.944v2.544a.372.372,0,0,0,.379.364H55.37l.552.682v1.875h-1.05a.364.364,0,1,0,0,.727H56.3a.372.372,0,0,0,.379-.364V82.713a.354.354,0,0,0-.078-.223Z"
                          transform="translate(-44.68 -78.521)"
                          fill="#fff"
                        />
                        <path
                          id="Path_70"
                          data-name="Path 70"
                          d="M28.4,271.6h-1.68a.365.365,0,1,0,0,.731H28.4a.365.365,0,1,0,0-.731Z"
                          transform="translate(-25.756 -267.248)"
                          fill="#fff"
                        />
                        <path
                          id="Path_71"
                          data-name="Path 71"
                          d="M3.482,208.134H.365a.365.365,0,1,0,0,.731H3.482a.365.365,0,1,0,0-.731Z"
                          transform="translate(0 -205.214)"
                          fill="#fff"
                        />
                        <path
                          id="Path_72"
                          data-name="Path 72"
                          d="M29.835,144.672H26.718a.365.365,0,1,0,0,.731h3.117a.365.365,0,1,0,0-.731Z"
                          transform="translate(-25.756 -143.182)"
                          fill="#fff"
                        />
                      </g>
                      <path
                        id="Path_73"
                        data-name="Path 73"
                        d="M13.5,4.5l-6,10"
                        transform="translate(661.5 1290.5)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span style={{ fontSize: "12px" }}> Not Serviceable</span>
              <span className="order_tooltip" />
            </span>
          ) : null}

          {order.order_tags && order.order_tags.length ? (
            <div className={`product-tag-wraper ${!serviceable ? "" : ""} `}>
              <p>Tags:</p>

              <Tag color="orange">{order.order_tags[0]}</Tag>
              {order.order_tags.length > 1 && (
                <Tag color="green"> {order.order_tags[1]}</Tag>
              )}
              {order.order_tags.length > 2 ? (
                <>
                  <Tooltip
                    overlayClassName="tag-overlay"
                    title={
                      <>
                        {order.order_tags.map((e, i) =>
                          i === 0 || i === 1 ? null : (
                            <Tag
                              color={
                                tagColorArr[Math.floor(Math.random() * 9) + 1]
                              }
                            >
                              {e}
                            </Tag>
                          )
                        )}
                      </>
                    }
                  >
                    <div className="more">
                      + {order.order_tags.length - 2} more
                    </div>
                  </Tooltip>
                </>
              ) : null}
            </div>
          ) : null}

          {Object.keys(this.state.errors).length > 0 && (
            <div className="error_tag">
              <Icon type="exclamation-circle" /> Customer Info
            </div>
          )}
        </div>

        <div className="middleupperdata">
          <div className="right_data">
            <h3>
              <span>
                {this.state.notLinked ? (
                  <span>
                    <Checkbox
                      className={
                        lineItems.length === this.state.selectedLineItems.length
                          ? null
                          : "myinput"
                      }
                      style={{ marginRight: 8 }}
                      checked={
                        lineItems.length === this.state.selectedLineItems.length
                      }
                      onChange={(e) => this.selectAllNone(e.target.checked)}
                    />
                  </span>
                ) : (
                  !provisional &&
                  serviceable &&
                  hasAtleastOneOpen &&
                  this.props.pageType === "openorders" && (
                    <span>
                      <Checkbox
                        className={
                          lineItems.length ===
                          this.state.selectedLineItems.length
                            ? null
                            : "myinput"
                        }
                        style={{ marginRight: 8 }}
                        checked={
                          lineItems.length ===
                          this.state.selectedLineItems.length
                        }
                        disabled={
                          Object.keys(this.state.errors).length > 0
                            ? true
                            : false
                        }
                        onChange={(e) => this.selectAllNone(e.target.checked)}
                      />
                    </span>
                  )
                )}
              </span>

              <span
                className="mr-3"
                style={{ fontSize: 12, fontWeight: 400, color: "#898989" }}
              >
                <Tooltip title={`${SHOPIFY_NAME} Order ID`}>
                  {" "}
                  Store Order:
                </Tooltip>{" "}
                <span className="blue-text" style={{ fontWeight: 400 }}>
                  <a
                    href={order.order_link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {order.order_shopify.name}
                  </a>
                </span>
              </span>
              <span
                className="mr-4"
                style={{ color: "#898989", marginTop: "2px", fontWeight: 400 }}
              >
                |
              </span>
              <span className="mr-3" style={{ marginRight: "8px" }}>
                <Tooltip
                  title={`${
                    paymentStatusText[order.order_payment_status] === "Pending"
                      ? "Cash On Delivery"
                      : paymentStatusText[order.order_payment_status] ===
                        "Fully Paid"
                      ? "Prepaid"
                      : paymentStatusText[order.order_payment_status]
                  }  `}
                >
                  <Icon
                    component={
                      order.order_currency === "INR"
                        ? PaymentStatusIcon
                        : PaymentStatusIconglobal
                    }
                    style={{
                      color: this.props.paymentStatusColors[
                        order.order_payment_status
                      ],
                    }}
                  />
                </Tooltip>
              </span>
              <span
                className="mr-3"
                style={{ fontSize: 12, fontWeight: 400, color: "#898989" }}
              >
                <Tooltip
                  title={`Total Order Cost = Total ${SHOPIFY_NAME} Item Selling Price + Shipping Price`}
                >
                  <span
                    className="blue-text"
                    style={{ fontWeight: 400, color: "#000000" }}
                  >
                    {getCurrencySymbol(
                      this.props.store.store_geo === "in"
                        ? "INR"
                        : this.props.currencies.store_currency
                    )}{" "}
                    {order.total_order_value ? order.total_order_value : "0"}
                  </span>
                </Tooltip>
              </span>

              <span
                className="mr-4"
                style={{ color: "#898989", marginTop: "2px", fontWeight: 400 }}
              >
                |
              </span>

              <span
                style={{ color: "#949494", fontSize: "12px", fontWeight: 400 }}
              >
                <Tooltip title={`${SHOPIFY_NAME} Order Date`}>
                  {moment(order.order_time).format("DD MMM, YYYY")}
                </Tooltip>
              </span>
            </h3>

            <h3 style={{ marginTop: "2px" }}>
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#898989",
                  marginRight: 6,
                }}
              >
                Customer:
              </span>{" "}
              <span
                className="mr-3 capitalize-text"
                style={{
                  fontSize: 12,
                  marginRight: 8,
                  fontWeight: 500,
                }}
              >
                {order.shipping_address &&
                !!order.shipping_address.name &&
                order.shipping_address.name.length > 10 ? (
                  <Tooltip title={order.shipping_address.name}>
                    {order.shipping_address.name &&
                    !!order.shipping_address.name
                      ? `${order.shipping_address.name
                          .substring(0, 10)
                          .toLowerCase()}...`
                      : "N/A"}
                  </Tooltip>
                ) : (
                  <span>
                    {order.shipping_address &&
                    !!order.shipping_address.name &&
                    order.shipping_address.name
                      ? order.shipping_address.name.toLowerCase()
                      : "N/A"}
                  </span>
                )}
              </span>
              <span style={{ marginRight: 8, fontSize: 12 }}>
                <Tooltip title={order.shipping_address.country}>
                  <img
                    src={order.customer_details.flag}
                    className="customer-country-flag"
                    alt="flag"
                  />
                </Tooltip>
              </span>
            </h3>
          </div>

          <div className="left_data">
            {this.props.pageType === "closedOrders" ||
            this.props.pageTab.includes("Closed Orders") ||
            (this.props.pageType === "allorders" &&
              this.props.pageTab.includes("all") &&
              shipmentStatusText[orderactionstatusican[0]] === "Delivered") ||
            shipmentStatusText[orderactionstatusican[0]] === "Returned" ||
            vfOrderStatusText[orderactionstatusican2[0]] ===
              "Cancelled Order" ||
            vfOrderStatusText[orderactionstatusican2[0]] ===
              "Do Not Fulfill" ? null : (
              <div style={{ minHeight: 32, marginRight: 8 }}>
                <Button
                  className="add-note-btn"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#0068FF",
                    border: "1px solid #0068FF",
                    borderRadius: "2px",
                  }}
                  onClick={() =>
                    this.props.toggleNotesModal(order._id["$oid"], (note) =>
                      this.onAddNote(note)
                    )
                  }
                >
                  <svg
                    id="filetext"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.977"
                    height="16"
                    viewBox="0 0 11.977 16"
                    style={{ marginRight: "4px" }}
                  >
                    <path
                      id="Shape"
                      d="M10.868,16H1.14A1.134,1.134,0,0,1,0,14.875V1.125A1.134,1.134,0,0,1,1.14,0H6.527A1.145,1.145,0,0,1,6.98.1L7,.1,7,.107l.021.01.067.036a1.213,1.213,0,0,1,.229.18l4.338,4.382a1.116,1.116,0,0,1,.318.793v9.367A1.105,1.105,0,0,1,10.868,16ZM1.124,1.125v13.75h9.728V6H7.12a1.134,1.134,0,0,1-1.14-1.125V1.125Zm5.981.6v3.15H10.24Zm.453,11.4h-5a.562.562,0,0,1,0-1.125h5a.562.562,0,0,1,0,1.125Zm2-2.5h-7a.562.562,0,0,1,0-1.125h7a.562.562,0,0,1,0,1.125Zm-3-2.5h-4A.562.562,0,0,1,2.561,7h4a.562.562,0,0,1,0,1.125Z"
                      transform="translate(0 0)"
                      fill="#0068ff"
                    />
                  </svg>
                  Add Note
                </Button>
              </div>
            )}

            {!!this.state.notLinked ? (
              this.props.order.place_status &&
              this.props.order.place_status === "lock" ? (
                <Tooltip
                  title="Order placement is already in progress"
                  className={
                    disableunlinkded
                      ? "dropdownmenu diable-dropdownmenu"
                      : "dropdownmenu"
                  }
                  style={{
                    pointerEvents: this.state.editabledisable ? "none" : null,
                    backgroundColor: this.state.editabledisable
                      ? "#A4C6F8"
                      : null,
                  }}
                >
                  {" "}
                  <span>
                    <div
                      className={
                        disableunlinkded
                          ? " disable-dropdown custom_dropdown"
                          : "custom_dropdown"
                      }
                    >
                      {console.log(
                        "check",
                        this.props.order.place_status,
                        disableunlinkded
                      )}
                      <Dropdown
                        onVisibleChange={(e) => {
                          this.setState({ showHideheaderdata: e });
                        }}
                        className={disableunlinkded ? "disable" : null}
                        disabled={disableunlinkded ? true : false}
                        // visible={true}
                        overlay={
                          <Menu
                            style={{
                              minWidth: 128,
                              marginLeft: -10,
                              marginTop: 4,
                            }}
                            onClick={({ key }) =>
                              this.selectAllAndConfirm(
                                key,
                                order.order_shopify.name,
                                order
                              )
                            }
                            className="custom_dropdown2"
                          >
                            {vfOrderStatusText[allSameStatus] ===
                            "Do Not Fulfill" ? null : (
                              <Menu.Item key="nofulfill">
                                <a href="#">Do Not Fulfill</a>
                              </Menu.Item>
                            )}

                            {hasPermission(
                              this.props.store,
                              "cancel_order"
                            ) && (
                              <Menu.Item key="cancel">
                                <a href="#">Cancel</a>
                              </Menu.Item>
                            )}
                          </Menu>
                        }
                        type="primary"
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            marginBottom: "2px",
                            width: "100px",
                            fontWeight: 500,
                            position: "absolute",
                            transform: "translate(-50%, 34%)",
                            cursor: "pointer",
                          }}
                        >
                          Take Action
                          <span>
                            <svg
                              style={{
                                marginLeft: "5px",
                                transition: "all 0.5s",
                                transform: `${
                                  this.state.showHideheaderdata
                                    ? "rotateX(180deg)"
                                    : "rotateX(0deg)"
                                }`,
                                cursor: "pointer",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="8"
                              viewBox="0 0 16 8"
                            >
                              <g
                                id="Polygon_49"
                                data-name="Polygon 49"
                                transform="translate(16 8) rotate(180)"
                                fill={`${
                                  this.state.showHideheaderdata
                                    ? "#fff"
                                    : "none"
                                }`}
                              >
                                <path d="M8,0l8,8H0Z" stroke="none" />
                                <path
                                  d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                  stroke="none"
                                  fill="#fff"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                      </Dropdown>
                    </div>
                  </span>
                </Tooltip>
              ) : (
                <span
                  className={
                    disableunlinkded
                      ? "dropdownmenu diable-dropdownmenu"
                      : "dropdownmenu"
                  }
                  style={{
                    pointerEvents: this.state.editabledisable ? "none" : null,
                    backgroundColor: this.state.editabledisable
                      ? "#A4C6F8"
                      : null,
                  }}
                >
                  <div
                    className={
                      disableunlinkded
                        ? " disable-dropdown custom_dropdown"
                        : "custom_dropdown"
                    }
                  >
                    {console.log(
                      "check",
                      this.props.order.place_status,
                      disableunlinkded
                    )}
                    <Dropdown
                      onVisibleChange={(e) => {
                        this.setState({ showHideheaderdata: e });
                      }}
                      className={disableunlinkded ? "disable" : null}
                      disabled={disableunlinkded ? true : false}
                      // visible={true}
                      overlay={
                        <Menu
                          style={{
                            minWidth: 128,
                            marginLeft: -10,
                            marginTop: 4,
                          }}
                          onClick={({ key }) =>
                            this.selectAllAndConfirm(
                              key,
                              order.order_shopify.name,
                              order
                            )
                          }
                          className="custom_dropdown2"
                        >
                          {vfOrderStatusText[allSameStatus] ===
                          "Do Not Fulfill" ? null : (
                            <Menu.Item key="nofulfill">
                              <a href="#">Do Not Fulfill</a>
                            </Menu.Item>
                          )}

                          {hasPermission(this.props.store, "cancel_order") && (
                            <Menu.Item key="cancel">
                              <a href="#">Cancel</a>
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                      type="primary"
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          marginBottom: "2px",
                          width: "100px",
                          fontWeight: 500,
                          position: "absolute",
                          transform: "translate(-50%, 34%)",
                          cursor: "pointer",
                        }}
                      >
                        Take Action
                        <span>
                          <svg
                            style={{
                              marginLeft: "5px",
                              transition: "all 0.5s",
                              transform: `${
                                this.state.showHideheaderdata
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                              cursor: "pointer",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_49"
                              data-name="Polygon 49"
                              transform="translate(16 8) rotate(180)"
                              fill={`${
                                this.state.showHideheaderdata ? "#fff" : "none"
                              }`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </span>
                      </span>
                    </Dropdown>
                  </div>
                </span>
              )
            ) : provisional ? (
              <span>
                <Tooltip
                  title={
                    this.state.order &&
                    this.state.order.line_items &&
                    this.state.order.line_items[0].provisional_remark ? (
                      <p
                        style={{
                          fontSize: "12px",
                          letterSpacing: "0.032em",
                          lineHeight: "16px",
                          textAlign: "center",
                        }}
                      >
                        {this.state.order.line_items[0].provisional_remark}
                      </p>
                    ) : (
                      <p
                        style={{
                          fontSize: "12px",
                          letterSpacing: "0.032em",
                          lineHeight: "16px",
                          textAlign: "center",
                        }}
                      >
                        You can take action on this order after linking the
                        product.
                      </p>
                    )
                  }
                >
                  <span
                    className="dropdownmenu2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      justifyContent: "center",
                    }}
                  >
                    Take Action
                    <span
                      className="order_tooltip"
                      style={{ marginLeft: "8px", marginTop: "3px" }}
                    >
                      <svg
                        className="order-tooltip-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                      >
                        <g data-name="Group 2529">
                          <g
                            transform="translate(0 1)"
                            fill="none"
                            stroke="#fff"
                          >
                            <circle cx="8" cy="8" r="8" stroke="none" />
                            <circle cx="8" cy="8" r="7.5" />
                          </g>
                          <text
                            transform="translate(6.5)"
                            fill="#fff"
                            fontSize="12"
                            fontFamily="Poppins-Medium, Poppins"
                            fontWeight="500"
                            letterSpacing=".032em"
                          >
                            <tspan x="0" y="13">
                              i
                            </tspan>
                          </text>
                        </g>
                      </svg>
                    </span>
                    {/* <Icon type="info-circle" /> */}
                  </span>
                </Tooltip>
              </span>
            ) : (hasAtleastOneOpen || allUnlinked) && !cancellable ? (
              <span
                className={
                  this.props.order.place_status &&
                  this.props.order.place_status === "lock"
                    ? "dropdownmenu2 diable-dropdownmenu"
                    : allUnlinked
                    ? "dropdownmenu2"
                    : hasAtleastOneOpen && !cancellable
                    ? "dropdownmenu"
                    : null
                }
                style={{
                  pointerEvents: this.state.editabledisable ? "none" : null,
                  backgroundColor: this.state.editabledisable
                    ? "#A4C6F8"
                    : null,
                }}
              >
                <Tooltip
                  title={
                    this.props.order.place_status &&
                    this.props.order.place_status === "lock" ? (
                      "Order placement is already in progress"
                    ) : allUnlinked &&
                      this.state.order.line_items[0].provisional_remark ? (
                      <p className="provisional-remark">
                        {this.state.order.line_items[0].provisional_remark}
                      </p>
                    ) : allUnlinked ? (
                      <p className="provisional-remark">
                        You can take action on this order after linking the
                        product
                      </p>
                    ) : null
                  }
                >
                  <div
                    className={
                      (this.props.order.place_status &&
                        this.props.order.place_status === "lock") ||
                      allUnlinked
                        ? "disable-dropdown custom_dropdown"
                        : "custom_dropdown"
                    }
                  >
                    <Dropdown
                      disabled={
                        (this.props.order.place_status &&
                          this.props.order.place_status === "lock") ||
                        allUnlinked
                          ? true
                          : false
                      }
                      className={
                        (this.props.order.place_status &&
                          this.props.order.place_status === "lock") ||
                        allUnlinked
                          ? "disable"
                          : ""
                      }
                      onVisibleChange={(e) => {
                        this.setState({ showHideheaderdata: e });
                      }}
                      // visible={true}
                      overlay={
                        <Menu
                          style={{
                            minWidth: 128,
                            marginLeft: -10,
                            marginTop: 4,
                          }}
                          onClick={({ key }) =>
                            this.selectAllAndConfirm(
                              key,
                              order.order_shopify.name,
                              order
                            )
                          }
                          className="custom_dropdown2"
                        >
                        
                          {serviceable &&
                          Object.keys(this.state.errors).length === 0 ? (
                            <Menu.Item key="verifyorder">
                              <a href="#"> Place Order </a>
                            </Menu.Item>
                          ) : paymentStatusText[order.order_payment_status] ===
                              "Fully Paid" &&
                            this.state.order.is_servicable === 1 &&
                            Object.keys(this.state.errors).length === 0 ? (
                            <Menu.Item key="verifyorder">
                              <a href="#"> Place Order </a>
                            </Menu.Item>
                          ) : null}

                          {/* <Menu.Divider /> */}

                          {vfOrderStatusText[allSameStatus] ===
                          "Verified" ? null : !isverifychecked ? null : this
                              .state.order.is_servicable === 1 &&
                            Object.keys(this.state.errors).length === 0 ? (
                            <Menu.Item key="verify">
                              <a href="#">Verify</a>
                            </Menu.Item>
                          ) : null}

                          {/* <Menu.Divider /> */}
                          <Menu.Item key="hold">
                            <a href="#">Put On Hold</a>
                          </Menu.Item>
                          {/* <Menu.Divider /> */}

                          {vfOrderStatusText[allSameStatus] ===
                          "Do Not Fulfill" ? null : (
                            <Menu.Item key="nofulfill">
                              <a href="#">Do Not Fulfill</a>
                            </Menu.Item>
                          )}
                          {/* {hasPermission(this.props.store, "cancel_order") && (
                            <Menu.Divider />
                          )} */}
                          {hasPermission(this.props.store, "cancel_order") && (
                            <Menu.Item key="cancel">
                              <a href="#">Cancel</a>
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                      type="primary"
                    >
                      {serviceable ? (
                        <span
                          style={{
                            fontSize: "12px",
                            marginBottom: "2px",
                            width: "108px",
                            fontWeight: "500",
                            position: "absolute",
                            transform: "translate(-50%, 34%)",
                            lineHeight: allUnlinked ? "13.9px" : null,
                            cursor: "pointer",
                          }}
                        >
                          Take Action
                          <span>
                            {allUnlinked ? (
                              <span
                                className="order_tooltip"
                                style={{ marginLeft: "8px" }}
                              >
                                <svg
                                  className="order-tooltip-icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                >
                                  <g data-name="Group 2529">
                                    <g
                                      transform="translate(0 1)"
                                      fill="none"
                                      stroke="#fff"
                                    >
                                      <circle
                                        cx="8"
                                        cy="8"
                                        r="8"
                                        stroke="none"
                                      />
                                      <circle cx="8" cy="8" r="7.5" />
                                    </g>
                                    <text
                                      transform="translate(6.5)"
                                      fill="#fff"
                                      fontSize="12"
                                      fontFamily="Poppins-Medium, Poppins"
                                      fontWeight="500"
                                      letterSpacing=".032em"
                                    >
                                      <tspan x="0" y="13">
                                        i
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </span>
                            ) : (
                              <svg
                                style={{
                                  marginLeft: "5px",
                                  transition: "all 0.5s",
                                  transform: `${
                                    this.state.showHideheaderdata
                                      ? "rotateX(180deg)"
                                      : "rotateX(0deg)"
                                  }`,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="8"
                                viewBox="0 0 16 8"
                              >
                                <g
                                  id="Polygon_49"
                                  data-name="Polygon 49"
                                  transform="translate(16 8) rotate(180)"
                                  fill={`${
                                    this.state.showHideheaderdata
                                      ? "#fff"
                                      : "none"
                                  }`}
                                >
                                  <path d="M8,0l8,8H0Z" stroke="none" />
                                  <path
                                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                    stroke="none"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            )}
                          </span>
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: "12px",
                            marginBottom: "2px",
                            width: "100px",
                            fontWeight: 500,
                            position: "absolute",
                            transform: "translate(-50%, 34%)",
                            lineHeight: allUnlinked ? "13.9px" : null,
                            cursor: "pointer",
                          }}
                        >
                          Take Action
                          <span>
                            {allUnlinked ? (
                              <span
                                className="order_tooltip"
                                style={{ marginLeft: "8px" }}
                              >
                                <svg
                                  className="order-tooltip-icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                >
                                  <g data-name="Group 2529">
                                    <g
                                      transform="translate(0 1)"
                                      fill="none"
                                      stroke="#fff"
                                    >
                                      <circle
                                        cx="8"
                                        cy="8"
                                        r="8"
                                        stroke="none"
                                      />
                                      <circle cx="8" cy="8" r="7.5" />
                                    </g>
                                    <text
                                      transform="translate(6.5)"
                                      fill="#fff"
                                      fontSize="12"
                                      fontFamily="Poppins-Medium, Poppins"
                                      fontWeight="500"
                                      letterSpacing=".032em"
                                    >
                                      <tspan x="0" y="13">
                                        i
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </span>
                            ) : (
                              <svg
                                style={{
                                  marginLeft: "5px",
                                  transition: "all 0.5s",
                                  transform: `${
                                    this.state.showHideheaderdata
                                      ? "rotateX(180deg)"
                                      : "rotateX(0deg)"
                                  }`,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="8"
                                viewBox="0 0 16 8"
                              >
                                <g
                                  id="Polygon_49"
                                  data-name="Polygon 49"
                                  transform="translate(16 8) rotate(180)"
                                  fill={`${
                                    this.state.showHideheaderdata
                                      ? "#fff"
                                      : "none"
                                  }`}
                                >
                                  <path d="M8,0l8,8H0Z" stroke="none" />
                                  <path
                                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                    stroke="none"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            )}
                          </span>
                        </span>
                      )}
                    </Dropdown>
                  </div>
                </Tooltip>
              </span>
            ) : cancellable && this.props.store.store_geo !== "global" ? (
              <Button
                className="clickable"
                onClick={() => this.checkVerifiedCancel()}
              >
                Cancel Order
              </Button>
            ) : (
              orderOverallStatus
            )}
          </div>
        </div>

        <div className="tabs-row">
          <ul>
            {this.props.pageType !== "trackorders" &&
              this.props.pageType !== "ndr" && (
                <li
                  className={
                    this.state.activeTab === "lineItems" ? "active" : ""
                  }
                  onClick={() => this.setActiveTab("lineItems")}
                >
                  Line Items
                </li>
              )}
            {this.props.pageType !== "trackorders" &&
              this.props.pageType !== "ndr" &&
              (this.state.editabledisable ? (
                <li
                  // className={
                  //   this.state.activeTab === "customerInfo" ? "active" : ""
                  // }
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: 0.5,
                  }}
                  // onClick={() => this.setActiveTab("customerInfo")}
                >
                  Customer Info
                  {Object.keys(this.state.errors).length > 0 && (
                    <Tooltip
                      placement="top"
                      title="Some required customer details are missing!"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 56 56"
                        style={{
                          width: 20,
                          marginLeft: 5,
                          enableBackground: "new 0 0 56 56",
                          animation: "pulse 2s infinite",
                          borderRadius: "100%",
                        }}
                      >
                        <circle cx="28" cy="28" r="28" fill="#EF6354" />
                        <text
                          transform="matrix(1.0649 0 0 1 21.1265 43.6343)"
                          fill="#FFFFFF"
                          fontSize="43.759px"
                        >
                          !
                        </text>
                      </svg>
                    </Tooltip>
                  )}
                </li>
              ) : (
                <li
                  className={
                    this.state.activeTab === "customerInfo" ? "active" : ""
                  }
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => this.setActiveTab("customerInfo")}
                >
                  Customer Info
                  {Object.keys(this.state.errors).length > 0 && (
                    <Tooltip
                      placement="top"
                      title="Some required customer details are missing!"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 56 56"
                        style={{
                          width: 20,
                          marginLeft: 5,
                          enableBackground: "new 0 0 56 56",
                          animation: "pulse 2s infinite",
                          borderRadius: "100%",
                        }}
                      >
                        <circle cx="28" cy="28" r="28" fill="#EF6354" />
                        <text
                          transform="matrix(1.0649 0 0 1 21.1265 43.6343)"
                          fill="#FFFFFF"
                          fontSize="43.759px"
                        >
                          !
                        </text>
                      </svg>
                    </Tooltip>
                  )}
                </li>
              ))}

            {this.state.editabledisable ? (
              <li
                // className={this.state.activeTab === "shipments" ? "active" : ""}
                // onClick={() => this.setActiveTab("shipments")}
                style={{ opacity: 0.5 }}
              >
                Shipments{""}
                <Avatar
                  style={{
                    backgroundColor: "#FF9400",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {this.props.order && this.props.order.order_shipments
                    ? this.props.order.order_shipments.length
                    : 0}
                </Avatar>
              </li>
            ) : (
              <li
                className={this.state.activeTab === "shipments" ? "active" : ""}
                onClick={() => this.setActiveTab("shipments")}
              >
                Shipments{""}
                <Avatar
                  style={{
                    backgroundColor: "#FF9400",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {this.props.order && this.props.order.order_shipments
                    ? this.props.order.order_shipments.length
                    : 0}
                </Avatar>
              </li>
            )}

            {this.state.editabledisable ? (
              <li
                // className={this.state.activeTab === "notes" ? "active" : ""}
                // onClick={() => this.setActiveTab("notes")}
                style={{ opacity: 0.5 }}
              >
                Notes{" "}
                <Avatar
                  style={{
                    backgroundColor: "#FF9400",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {notes ? notes.length : 0}
                </Avatar>
              </li>
            ) : (
              <li
                className={this.state.activeTab === "notes" ? "active" : ""}
                onClick={() => this.setActiveTab("notes")}
              >
                Notes{" "}
                <Avatar
                  style={{
                    backgroundColor: "#FF9400",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {notes ? notes.length : 0}
                </Avatar>
              </li>
            )}

            {this.state.editabledisable ? (
              <li
                // className={this.state.activeTab === "logs" ? "active" : ""}
                // onClick={() => this.setActiveTab("logs")}
                style={{ opacity: 0.5 }}
              >
                Logs{" "}
                <Avatar
                  style={{
                    backgroundColor: "#FF9400",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {logs ? logs.length : 0}
                </Avatar>
              </li>
            ) : (
              <li
                className={this.state.activeTab === "logs" ? "active" : ""}
                onClick={() => this.setActiveTab("logs")}
              >
                Logs{" "}
                <Avatar
                  style={{
                    backgroundColor: "#FF9400",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {logs ? logs.length : 0}
                </Avatar>
              </li>
            )}

            {repitiveOrders && repitiveOrders.length ? (
              this.state.editabledisable ? (
                <li
                  // className={this.state.activeTab === "logs" ? "active" : ""}
                  // onClick={() => this.setActiveTab("logs")}
                  style={{ opacity: 0.5 }}
                >
                  Duplicate Orders{" "}
                  <Avatar
                    style={{
                      backgroundColor: "#FF9400",
                      verticalAlign: "middle",
                      marginLeft: 10,
                    }}
                  >
                    {repitiveOrders ? repitiveOrders.length : 0}
                  </Avatar>
                </li>
              ) : (
                <li
                  className={
                    this.state.activeTab === "repitiveOrders" ? "active" : ""
                  }
                  onClick={() => this.setActiveTab("repitiveOrders")}
                >
                  Duplicate Orders{" "}
                  <Avatar
                    style={{
                      backgroundColor: "#FF9400",
                      verticalAlign: "middle",
                      marginLeft: 10,
                    }}
                  >
                    {repitiveOrders ? repitiveOrders.length : 0}
                  </Avatar>
                </li>
              )
            ) : null}

            <li style={{ float: "right", padding: "4px 36px" }}>
              {" "}
              {!provisional &&
                this.state.activeTab === "lineItems" &&
                this.state.selectedLineItems.length > 0 && (
                  <Dropdown
                    onVisibleChange={(e) => {
                      this.setState({ showHideheaderdata2: e });
                    }}
                    overlay={
                      <Menu
                        style={{ minWidth: 136 }}
                        onClick={({ key }) =>
                          this.props.toggleConfirmationModal(
                            key,
                            order.order_shopify.name,
                            order
                          )
                        }
                        className="custom_dropdown2"
                      >
                        {/* {!serviceable &&
                        paymentStatusText[order.order_payment_status] ===
                          "Pending" ? null : (
                          <Menu.Item
                            key="verifyorder"
                            disabled={
                              !serviceable &&
                              paymentStatusText[order.order_payment_status] ===
                                "Pending"
                            }
                          >
                            <a href="#">Place Order</a>
                          </Menu.Item>
                        )} */}
                        {/* <Menu.Divider /> */}

                        {vfOrderStatusText[allSameStatus] === "Verified" ||
                        !isverifychecked ? null : (
                          <Menu.Item key="verify">
                            <a href="#">Verify</a>
                          </Menu.Item>
                        )}

                        {/* {hasPermission(
                          this.props.store,
                          "verify_confirm_order"
                        ) && <Menu.Divider />} */}
                        <Menu.Item key="hold">
                          <a href="#">Put On Hold</a>
                        </Menu.Item>
                        {/* <Menu.Divider /> */}

                        {vfOrderStatusText[allSameStatus] ===
                        "Do Not Fulfill" ? null : (
                          <Menu.Item key="nofulfill">
                            <a href="#">Do Not Fulfill</a>
                          </Menu.Item>
                        )}

                        {/* <Menu.Divider /> */}
                        <Menu.Item key="cancel">
                          <a href="#">Cancel</a>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button className="bulk-action-btn">
                      Bulk Action{" "}
                      <svg
                        style={{
                          marginLeft: "5px",
                          transition: "all 0.5s",
                          transform: `${
                            this.state.showHideheaderdata2
                              ? "rotateX(180deg)"
                              : "rotateX(0deg)"
                          }`,
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="8"
                        viewBox="0 0 16 8"
                      >
                        <g
                          id="Polygon_49"
                          data-name="Polygon 49"
                          transform="translate(16 8) rotate(180)"
                          fill={`${
                            this.state.showHideheaderdata2 ? "#fff" : "none"
                          }`}
                        >
                          <path d="M8,0l8,8H0Z" stroke="none" />
                          <path
                            d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                            stroke="none"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </Button>
                  </Dropdown>
                )}
            </li>
          </ul>
          <LineItems
            filters={this.props.filters}
            cancelButtonStatus={cancellable}
            pageTab={this.props.pageTab}
            ordervf={order.order_vfid}
            activeTab={this.state.activeTab}
            lineItems={lineItems}
            serviceable={serviceable}
            selectedLineItems={this.state.selectedLineItems}
            provisional={provisional}
            provisional2={provisional2}
            {...this.props}
            disabledCheckbox={Object.keys(this.state.errors).length > 0}
            lineItemSelectedChange={(id) => this.lineItemSelectedChange(id)}
            changeLineItemQty={(id, qty) => this.changeLineItemQty(id, qty)}
            toggleLineItemEditable={(id) => this.toggleLineItemEditable(id)}
            logs={logs}
            closeordernav={this.props.closeordernav}
            allordernav={this.props.allordernav}
          />
          <CustomerInfo
            activeTab={this.state.activeTab}
            {...this.props}
            {...this.state}
            clear={() => this.clearError()}
            order={order}
          />
          <Shipment
            {...this.props}
            activeTab={this.state.activeTab}
            order={order}
            pageType={this.props.pageType}
            toggleNDRRemarksModal={this.props.toggleNDRRemarksModal}
          />
          <Notes activeTab={this.state.activeTab} notes={notes} />
          <Logs activeTab={this.state.activeTab} logs={logs} />
          <RepetitiveOrders
            store={this.props.store}
            activeTab={this.state.activeTab}
            pageType={this.props.pageType}
            logs={repitiveOrders}
            {...this.props}
          />
        </div>
      </Card>
    );
  }
}

export default OrderCardNew;
