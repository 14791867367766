import React from "react";
import { formatBalance } from "../../../../Helpers/Pricing";

import { Row, Skeleton, Tooltip, Icon, Table, Button } from "antd";
import "./index.scss";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";
import GlobalTooltipIconWhite from "../../Common/GlobalTooltipIconWhite";
import TooltipTitle from "../../Common/GlobalTooltipIconBlack";
import { ReactComponent as newTabIcon } from "../../../../assets/images/icons/openInNew.svg";

const TopPerformingProducts = (props) => {
  const {
    data,
    startDate,
    endDate,
    loading,
    error,
    errorCode,
    errorMsg,
  } = props;

  let exportObj = {
    catalogname: "Product Name",
    vfprodid: "Product ID",
    total_orders: "Placed Orders",
    order_total_attempts: "Attempted Orders",
    vf_to_attempts_percentage: "Attempted %",
    total_delivered_orders: "Delivered Orders",
    delivery_percentage: "Delivered %",
    image: "Image Link",
    fad: "FAD",
    fad_percentage: "FAD %",
    sad: "SAD",
    sad_percentage: "SAD %",
    tad: "TAD",
    tad_percentage: "TAD %",
    more_than_3: "More than 3 Attempts",
    more_than_3_percentage: "More than 3 Attempts %",
    total_delivered_order_amount: "Total Delivered Order Amount",
    order_total_paid: "Order Total Paid",
    confirm_orders: "Confirmed Orders",
  };

  function exportJSONToCSV(objArray) {
    var arr = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    var str =
      `${Object.keys(arr[0])
        .map((value) => `"${exportObj[value]}"`)
        .join(",")}` + "\r\n";

    var csvContent = arr.reduce((st, next) => {
      st +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return st;
    }, str);
    var element = document.createElement("a");
    element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
    element.target = "_blank";
    element.download = "export.csv";
    element.click();
  }

  const column = [
    {
      title: "Product Name",
      dataIndex: "province_code",
      key: "1",
      fixed: "left",
      width: 305,
      render: (a, b) => {
        return (
          <div className="name-head">
            <div className="product-wraper">
              <div className="img">
                <img src={b.image} alt="" />
              </div>
              <div className="details">
                {tooltipHandler(b.catalogname, b.vfprodid)}

                <div className="id">PRODUCT ID: {b.vfprodid}</div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Shopify Orders",
      dataIndex: "total_shopify_orders",
      key: "10",
      align: "center",
      width: 120,
      sorter: (a, b) =>
        Number(a.total_shopify_orders) - Number(b.total_shopify_orders),
    },
    {
      title: "Placed Orders",
      dataIndex: "total_orders",
      key: "2",
      align: "center",
      width: 120,
      sorter: (a, b) => Number(a.total_orders) - Number(b.total_orders),
    },

    {
      title: "Confirmation Rate",
      dataIndex: "confirmation_rate",
      key: "16",
      align: "center",
      width: 120,
      sorter: (a, b) => Number(a.total_orders) - Number(b.total_orders),
      render: (a, b) => {
        return (
          <>
            <p className="percentage-text">
              {Math.round(
                (Number(b.confirm_orders) / Number(b.total_shopify_orders)) *
                  100
              )}
              %
            </p>
            <p className="attempt-text">{b.confirm_orders}</p>
          </>
        );
      },
    },
    {
      title: "Attempted Orders",
      dataIndex: "vf_to_attempts_percentage",
      key: "3",
      align: "center",
      width: 120,
      sorter: (a, b) =>
        Number(a.vf_to_attempts_percentage) -
        Number(b.vf_to_attempts_percentage),

      render: (a, b) => {
        return (
          <>
            <p className="percentage-text">
              {Number.isInteger(Number(b.vf_to_attempts_percentage))
                ? Number(b.vf_to_attempts_percentage)
                : Number(b.vf_to_attempts_percentage).toFixed(2)}{" "}
              %
            </p>
            <p className="attempt-text">{b.order_total_attempts}</p>
          </>
        );
      },
    },
    // {
    //   title: "Attempted %",
    //   dataIndex: "vf_to_attempts_percentage",
    //   key: "5",
    //   align: "center",
    //   width: 130,
    //   sorter: (a, b) =>
    //     a.vf_to_attempts_percentage - b.vf_to_attempts_percentage,
    // },
    {
      title: (
        <p className="table-title-icon custom">
          Attempted Delivered Orders{" "}
          <TooltipTitle tooltipText="This delivery rate is derived from All the Attempted Order Deliveries vs Successful Deliveries" />{" "}
        </p>
      ),
      dataIndex: "delivery_percentage",
      key: "4",
      align: "center",
      width: 130,
      sorter: (a, b) =>
        Number(a.delivery_percentage) - Number(b.delivery_percentage),

      render: (a, b) => {
        return (
          <>
            <p className="percentage-text">
              {" "}
              {Number.isInteger(Number(b.delivery_percentage))
                ? Number(b.delivery_percentage)
                : Number(b.delivery_percentage).toFixed(2)}{" "}
              %
            </p>

            <p className="attempt-text">
              {b.total_delivered_orders && Number(b.total_delivered_orders)}
            </p>
          </>
        );
      },
    },

    // {
    //   title: "Delivery %",
    //   dataIndex: "delivery_percentage",
    //   key: "6",
    //   align: "center",
    //   width: 130,
    //   sorter: (a, b) => a.delivery_percentage - b.delivery_percentage,
    // },

    {
      title: (
        <p className="table-title-icon custom">
          FAD <TooltipTitle tooltipText="First Attempt Delivery %" />{" "}
        </p>
      ),
      dataIndex: "fad_percentage",
      key: "5",
      align: "center",
      width: 130,
      sorter: (a, b) => Number(a.fad_percentage) - Number(b.fad_percentage),

      render: (a, b) => {
        return (
          <>
            <p className="percentage-text">
              {Number.isInteger(Number(b.fad_percentage))
                ? Number(b.fad_percentage)
                : Number(b.fad_percentage).toFixed(2)}{" "}
              %
            </p>

            <p className="attempt-text">{!!b.fad && Number(b.fad)}</p>
          </>
        );
      },
    },

    // {
    //   title: "FAD %",
    //   dataIndex: "fad_percentage",
    //   key: "8",
    //   align: "center",
    //   width: 130,
    //   sorter: (a, b) => a.fad_percentage - b.fad_percentage,
    // },

    {
      title: (
        <p className="table-title-icon custom">
          SAD <TooltipTitle tooltipText="Second Attempted Delivery %" />{" "}
        </p>
      ),
      dataIndex: "sad_percentage",
      key: "6",
      align: "center",
      width: 130,
      sorter: (a, b) => Number(a.sad_percentage) - Number(b.sad_percentage),

      render: (a, b) => {
        return (
          <>
            <p className="percentage-text">
              {Number.isInteger(Number(b.sad_percentage))
                ? Number(b.sad_percentage)
                : Number(b.sad_percentage).toFixed(2)}{" "}
              %
            </p>

            <p className="attempt-text">{!!b.sad && Number(b.sad)}</p>
          </>
        );
      },
    },

    // {
    //   title: "SAD %",
    //   dataIndex: "sad_percentage",
    //   key: "10",
    //   align: "center",
    //   width: 130,
    //   sorter: (a, b) => a.sad_percentage - b.sad_percentage,
    // },

    {
      title: (
        <p className="table-title-icon custom">
          TAD <TooltipTitle tooltipText="3 or More Attempted Delivery %" />{" "}
        </p>
      ),
      dataIndex: "tad_percentage",
      key: "7",
      align: "center",
      width: 130,
      sorter: (a, b) =>
        Number(a.tad_percentage + a.more_than_3_percentage) -
        Number(b.tad_percentage + a.more_than_3_percentage),

      render: (a, b) => {
        return (
          <>
            <p className="percentage-text">
              {Number.isInteger(
                Number(b.tad_percentage) + Number(b.more_than_3_percentage)
              )
                ? Number(b.tad_percentage) + Number(b.more_than_3_percentage)
                : (
                    Number(b.tad_percentage) + Number(b.more_than_3_percentage)
                  ).toFixed(2)}
              {/* {Math.round(
                Number(b.tad_percentage) + Number(b.more_than_3_percentage)
              )}{" "} */}
              %
            </p>

            <p className="attempt-text">
              {!!b.tad && Number(b.tad) + Number(b.more_than_3)}
            </p>
          </>
        );
      },
    },

    // {
    //   title: "TAD %",
    //   dataIndex: "tad_percentage",
    //   key: "12",
    //   align: "center",
    //   width: 130,
    //   sorter: (a, b) => a.tad_percentage - b.tad_percentage,
    // },.more_than_3.more_than_3.more_than_3.more_than_3.more_than_3

    // {
    //   title: "More then 3 Attempt",
    //   dataIndex: "more_than_3_percentage",
    //   key: "8",
    //   align: "center",
    //   width: 130,
    //   sorter: (a, b) => Number(a.more_than_3) - Number(b.more_than_3),
    //   render: (a, b) => {
    //     return (
    //       <>
    //         <p className="percentage-text">{b.more_than_3_percentage}%</p>
    //         <p className="attempt-text">{b.more_than_3}</p>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "More then 3 Attempt %",
    //   dataIndex: "more_than_3_attempt_percentage",
    //   key: "14",
    //   align: "center",
    //   width: 130,
    //   sorter: (a, b) =>
    //     a.more_than_3_attempt_percentage - b.more_than_3_attempt_percentage,
    // },

    {
      title: "Delivered Orders Amount (₹)",
      dataIndex: "total_delivered_order_amount",
      key: "9",
      width: 140,
      // fixed: "right",
      align: "center",
      sorter: (a, b) =>
        Number(a.total_delivered_order_amount) -
        Number(b.total_delivered_order_amount),
      render: (a, b) => <p>{formatBalance(a)}</p>,
    },
  ];

  function tooltipHandler(name, id) {
    try {
      if (name.length > 28) {
        return (
          <div className="name-wraper underline-animation">
            <Tooltip title={name}>
              <a
                // href={`./products/${id}`}
                onClick={() =>
                  window.location.assign(
                    `${process.env.REACT_APP_NEW_APP}` + "/productDetails/" + id
                  )
                }
                target="_blank"
                className="name "
              >
                {name.slice(0, 28).trim() + "..."}
              </a>

              <Icon component={newTabIcon} />
            </Tooltip>
          </div>
        );
      } else {
        return (
          <div className="name-wraper underline-animation">
            <a
             
                // href={`./products/${id}`}
                onClick={() =>
                  window.location.assign(
                    `${process.env.REACT_APP_NEW_APP}` + "/productDetails/" + id
                  )
                }
              target="_blank"
              className="name underline-animation"
            >
              {name}
            </a>
            <Icon component={newTabIcon} />
          </div>
        );
      }
    } catch {}
  }

  // check error
  if (!loading && error) {
    return (
      <div id="fade-in" className="topPerformingProductHeadWraper">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Top Performing Products</span> |{" "}
          <span>
            {startDate} - {endDate}
          </span>
        </div>

        <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />
      </div>
    );
  }

  if (!loading && data.length === 0) {
    return (
      <div id="fade-in" className="topPerformingProductHeadWraper">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Top Performing Products</span> |{" "}
          <span>
            {startDate} - {endDate}
          </span>
        </div>

        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={
            <p>
              Looks like there are no products data in{" "}
              <span>current Date Range</span>.
            </p>
          }
          thirdHeading={
            <p>
              Please <span>select another Date Range</span> from Date Picker to
              filter data.
            </p>
          }
        />
      </div>
    );
  }
  return (
    <>
      <div id="fade-in" className="topPerformingProductHeadWraper custom">
        <div className="topPerformingProductHead">
          {" "}
          <div className="title">
            <span className="name">Top Performing Products</span> |{" "}
            <span>
              {startDate} - {endDate}
            </span>
          </div>
          <Button
            className="clickable"
            onClick={() =>
              exportJSONToCSV(
                JSON.stringify(data.map(({ id, ...rest }) => ({ ...rest })))
              )
            }
          >
            Export CSV
          </Button>
        </div>
        <Table
          className="top-performing-table"
          columns={column}
          tableLayout={"fixed"}
          scroll={{ y: 471, x: 500 }}
          rowKey={(record, i) => i}
          dataSource={data}
          loading={loading}
          filtered={true}
          pagination={false}
        />
      </div>
    </>
  );
};

export default TopPerformingProducts;
