import React from "react";

import "./index.scss";

class OpenInStore extends React.Component {
  render() {
    return (
      <div className="view-in-store">
        <p
          className="view-in-store-hover-underline-animation"
          style={{ textAlign: "center" }}
        >
          <span>
            {" "}
            <a
              // href={this.props.url}
              onClick={() =>
                window.location.assign(
                  `${process.env.REACT_APP_NEW_APP}` + this.props.url
                )
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#FF005E",
                fontSize: "12px",
                display: "flex",
                lineHeight: "24px",
                letterSpacing: "0.032em",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  marginRight: "6px",
                }}
              >
                View In VF Catalog
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="11.207"
                viewBox="0 0 11 11.207"
              >
                <g
                  id="Group_3238"
                  data-name="Group 3238"
                  transform="translate(-961.462 -333.293)"
                >
                  <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M-4592.26-691.587h-3.945v10h10V-685.4"
                    transform="translate(5558.167 1025.587)"
                    fill="none"
                    stroke="#fd115e"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <g
                    id="Group_3178"
                    data-name="Group 3178"
                    transform="translate(964.865 334)"
                  >
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M-4590.015-685.029l6.885-6.96"
                      transform="translate(4590.015 691.988)"
                      fill="none"
                      stroke="#fd115e"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                    <path
                      id="Path_86"
                      data-name="Path 86"
                      d="M-4586.394-691.587h3.573v3.362"
                      transform="translate(4589.918 691.587)"
                      fill="none"
                      stroke="#fd115e"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </span>
        </p>
      </div>
    );
  }
}

export default OpenInStore;
