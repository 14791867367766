import React, { Component } from "react";
import "./index.scss";
import {
  Row,
  Card,
  Table,
  Button,
  Skeleton,
  message,
  Pagination,
  Tooltip,
  Modal,
} from "antd";
// import qs from "qs";
import "ant-design-pro/dist/ant-design-pro.css";
import moment from "moment";
import axios from "axios";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import MyWalletReviewModal from "../MyWalletReviewModal";
import WireTransferSuccessModal from "../WireTransferSuccessModal";
import NotFoundCard from "../../Common/NotFoundCard";
import { connect } from "react-redux";
import ModalStorage from "../MyWalletReviewModal/ModalStorageChanges";

class Transactions extends Component {
  state = {
    reviewModal: {
      visible: false,
      id: undefined,
    },
    reviewModalstorageCharges: {
      visible: false,
      id: undefined,
    },
    transactions: this.props.transactions,

    email: "",

    loading: {
      transactions: true,
    },

    sort: {
      field: "transaction_date",
      order: "descend",
    },
    wireModal: {
      visible: false,
      id: "",
    },

    storageModal: false,
    storageData: null,
  };

  toggleWireModal(id = "") {
    this.setState({
      wireModal: {
        visible: id ? true : false,
        id,
      },
    });
  }

  componentDidMount() {
    this.props.fetchTransactions();
    axios({
      url: process.env.REACT_APP_API_URL + "/user/get_user_basic_details",
      method: "post",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            loading: false,
            email: res.data.basicDetails.email,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMsg: res.data.msg,
          });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: true,
          errorMsg: e.message,
        });
      });

    this.props.storetoggle(this.props.store.id);
  }

  // componentWillUnmount() {
  //   if (this.props.cancelToken) {
  //     this.props.cancelToken15.cancel();
  //   }
  // }

  toggleReviewModal(id) {
    this.setState({
      reviewModal: {
        ...this.state.reviewModal,
        id,
        visible: !this.state.reviewModal.visible,
      },
    });
  }
  toggleStorageModal(id) {
    this.setState({
      reviewModalstorageCharges: {
        ...this.state.reviewModalstorageCharges,
        id,
        visible: !this.state.reviewModalstorageCharges.visible,
      },
    });
  }
  downloadCsv() {
    axios({
      url: process.env.REACT_APP_API_URL + "/wallet/export_all_transactions",
      method: "post",
      withCredentials: true,
      data: {
        currency: this.props.match.params.storegeo === "global" ? "USD" : "INR",

        filters: this.state.filters,
        sort: this.state.sort,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e);
      });
  }

  render() {
    const transactionsColumns = [
      {
        title: "Transaction ID",
        dataIndex: "transaction_date",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <span style={{ color: "#212121", fontWeight: "400" }}>
                {record.transaction_vfid}
              </span>
              <span
                style={{
                  fontSize: "11px",
                  display: "block",
                  color: "#212121",
                  fontWeight: "400",
                }}
              >
                {moment(text).format("DD MMM, YYYY")}
              </span>
            </div>
          );
        },
      },
      {
        title: " Store Name",
        dataIndex: "channel_name",
        width: "10%",
        render: (text, record) => {
          if (
            record.transaction_subtype === "razorpay_addfunds" ||
            record.transaction_subtype === "coupon_addfunds" ||
            record.transaction_subtype === "funds_added_admin" ||
            record.transaction_subtype === "cashback_addfunds" ||
            record.transaction_subtype === "withdraw_funds" ||
            record.transaction_subtype === "admin_note" ||
            record.transaction_subtype === "wire_addfunds"
          ) {
            return <span>N/A</span>;
          } else {
            return <span>{!!text && !!text.length ? text : "N/A"}</span>;
          }
        },
      },

      {
        title: "Transaction Head",
        dataIndex: "transaction_data",
        width: "25%",
        className: "transaction-details",
        render: (text, record) => {
          if (record.transaction_subtype === "order_placed") {
            return <span>{`${text.items.length} product(s) ordered`} </span>;
          } else if (record.transaction_subtype === "funds_added_admin") {
            return <span>Wallet Balance Added</span>;
          } else if (record.transaction_subtype === "razorpay_addfunds") {
            return <span>Wallet Balance Added via Razorpay</span>;
          } else if (record.transaction_subtype === "stripe_addfunds") {
            return <span>Wallet Balance Added via Stripe</span>;
          } else if (record.transaction_subtype === "cashback_addfunds") {
            return <span>Cashback</span>;
          } else if (record.transaction_subtype === "coupon_addfunds") {
            return <span>Wallet Balance Added via Coupon</span>;
          } else if (record.transaction_subtype === "wire_addfunds") {
            return <span>Funds Added via Wire Transfer</span>;
          } else if (record.transaction_subtype === "withdraw_funds") {
            return <span>Wallet Balance Withdrawn</span>;
          } else if (record.transaction_subtype === "client_sourcing_request") {
            return <span>Sourcing Request</span>;
          } else if (record.transaction_subtype === "rto_remit") {
            return <span>RTO Remittance</span>;
          } else if (record.transaction_subtype === "management_fee") {
            return <span>Tax Management Fees</span>;
          } else if (record.transaction_subtype === "payment_gatway_charges") {
            return <span>Payment Gatway Charges</span>;
          } else if (record.transaction_subtype === "prepaid_remittance") {
            return <span>Prepaid Remittance</span>;
          } else if (record.transaction_subtype === "cod_remit") {
            return <span>COD Remittance</span>;
          } else if (record.transaction_subtype === "cod_remit_new") {
            return <span>COD Remittance</span>;
          } else if (
            record.transaction_subtype === "order_confirmation_management_fee"
          ) {
            return <span>Order Management Fees</span>;
          } else if (record.transaction_subtype === "credit_note") {
            return (
              <span>
                Credit Note
                {/*<Button
                  type="primary"
                  size="small"
                  className="review-button"
                  onClick={() => this.toggleReviewModal(record._id["$oid"])}
                >
                  Review
                </Button>*/}
              </span>
            );
          } else if (record.transaction_subtype === "admin_note") {
            return <span>Manual Note</span>;
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return <span>Order Reverse Pickup</span>;
          } else if (
            record.transaction_subtype === "verified_order_cancellation"
          ) {
            return <span>Verified Order Cancellation Fee</span>;
          } else if (record.transaction_subtype === "order_refund") {
            return <span>Order Refund</span>;
          } else if (record.transaction_subtype === "order_placed_pp") {
            return <span>Product Price</span>;
          } else if (record.transaction_subtype === "order_placed_ff") {
            return <span>Fulfilment Fees</span>;
          } else if (record.transaction_subtype === "order_placed_fs") {
            return <span>Forward Shipping</span>;
          } else if (record.transaction_subtype === "order_placed_codf") {
            return <span>COD Fees</span>;
          } else if (record.transaction_subtype === "storage_charges") {
            return <span>Storage Charges</span>;
          } else if (record.transaction_subtype === "rto_shipping") {
            return <span>RTO Shipping</span>;
          } else if (record.transaction_subtype === "rto_handling_fee") {
            return <span>RTO Handling Fee</span>;
          } else if (record.transaction_subtype === "buyback_handling_fee") {
            return <span>BuyBack Handling Fee</span>;
          } else if (record.transaction_subtype === "order_cancellation_fee") {
            return <span>Order Cancellation Fee</span>;
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return <span>Reverse Pick Up </span>;
          } else if (record.transaction_subtype === "codf_reverse") {
            return <span>Reverse COD Fees</span>;
          } else if (record.transaction_subtype === "inward_fees") {
            return <span>Inward Fees</span>;
          } else if (record.transaction_subtype === "credit_note_pp") {
            return <span>Product Price Credit</span>;
          } else if (record.transaction_subtype === "credit_note_ff") {
            return <span>Fullfillment Fee Credit</span>;
          } else if (record.transaction_subtype === "credit_note_fs") {
            return <span>Shipping Charges credit</span>;
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions"
          ) {
            return (
              <span className="gst-deductions-on-deliver">
                <Tooltip
                  title={"GST deductions are done on delivered order only"}
                >
                  GST Deductions
                </Tooltip>
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_reverse") {
            return <span>Cod Remittance Reverse</span>;
          } else if (record.transaction_subtype === "cod_remit_new_reverse") {
            return <span>Cod Remittance New Reverse</span>;
          } else if (
            record.transaction_subtype === "prepaid_remittance_reverse"
          ) {
            return <span>Prepaid Remittance Reverse</span>;
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions_reverse"
          ) {
            return (
              <span>
                {/* Orderamount Gst Deductions Reverse */}
                COD GST Deductions Reverse
              </span>
            );
          } else if (record.transaction_subtype === "management_fee_reverse") {
            return <span>Tax Management Fee Reverse</span>;
          } else if (record.transaction_subtype === "payment_gatway_fee") {
            return <span>Payment Gateway Fee</span>;
          } else if (record.transaction_subtype === "inventory_rtv") {
            return <span>Inventory RTV</span>;
          }

          //
          else if (record.transaction_subtype === "platform_fee_fixed") {
            return <span>Fixed Convenience Fee</span>;
          } else if (
            record.transaction_subtype === "credit_note_platform_fee_fixed"
          ) {
            return <span>Fixed Convenience Fee Reverse</span>;
          } else if (record.transaction_subtype === "platform_fee_percentage") {
            return <span>Percentage Convenience Fee</span>;
          } else if (
            record.transaction_subtype === "credit_note_platform_fee_percentage"
          ) {
            return <span>Percentage Convenience Fee Reverse</span>;
          } else if (
            record.transaction_subtype === "platform_fee_percentage_reverse"
          ) {
            return <span>Percentage Convenience Fee Reverse</span>;
          } else if (record.transaction_subtype === "storage_charges") {
            return <span>Storage Changes</span>;
          } else if (
            record.transaction_subtype === "client_sourcing_request_reverse"
          ) {
            return <span>Client Sourcing Request Reverse</span>;
          } else if (record.transaction_subtype === "inward_fees_reverse") {
            return <span>Inward Fees Reverse</span>;
          } else if (record.transaction_subtype === "wingman_charges") {
            return <span>Wingman Charges</span>;
          }
        },
      },

      // {
      //   title: "Transaction Head",
      //   dataIndex: "transaction_subtype",
      //   width: "15%",
      //   render: (type) => {
      //     const types = {
      //       rto_remit: "Buyback Credit",
      //       cod_remit: "COD Remittance",
      //       cod_remit_new: "COD Remittance New",
      //       funds_added_admin: "Add Funds - Manual",
      //       wire_addfunds: "Add Funds - Wire",
      //       order_placed: "vF Cost",
      //       razorpay_addfunds: "Add Funds - Razorpay",
      //       cashback_addfunds: "Add Funds - Cashback",
      //       withdraw_funds: "Withdraw Funds",
      //       coupon_addfunds: "Fund Addition",
      //       credit_note: "Credit Note",
      //       admin_note: "System Transaction",
      //       client_sourcing_request: "Sourcing Request",
      //       order_reverse_pickup: "Order Reverse Pickup",
      //       verified_order_cancellation: "Verified Order Cancellation Fee",
      //       order_refund: "Order Refund",
      //       order_placed_pp: "Product Price",
      //       order_placed_ff: "Fulfilment Fees",
      //       order_placed_fs: "Forward Shipping",
      //       order_placed_codf: "COD Fees",
      //       storage_charges: "Storage Charges",
      //       rto_shipping: "RTO Shipping",
      //       rto_handling_fee: "RTO Handling Fee",
      //       buyback_handling_fee: "BuyBack Handling Fee",
      //       order_cancellation_fee: "Order Cancellation Fee",
      //       reverse_pickup: "Reverse Pick Up",
      //       codf_reverse: "Reverse COD Fees",
      //       inward_fees: "Inward Fees",
      //       credit_note_pp: "Product Price Credit",
      //       credit_note_ff: "Fullfillment Fee Credit",
      //       credit_note_fs: "Shipping Charges credit",
      //       orderamount_gst_deductions: "Remittance GST Deductions",
      //     };
      //     return types[type];
      //   },
      // },
      {
        title: "Reference ID",
        dataIndex: "transaction_data",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <span class="tooltip">
                {record.transaction_subtype === "wingman_charges"
                  ? "N/A"
                  : record.transaction_subtype ===
                      "client_sourcing_request_reverse" ||
                    record.transaction_subtype === "inward_fees_reverse"
                  ? record.transaction_data.client_sourcing_request_vfid
                  : record.transaction_subtype === "inward_fees"
                  ? record.transaction_data.client_sourcing_request_vfid
                  : record.transaction_subtype === "inventory_rtv"
                  ? record.transaction_data.rtv_allocation_id
                  : [
                      "order_placed_ff",
                      "order_placed_pp",
                      "order_placed_fs",
                      "order_placed_codf",
                      "credit_note_ff",
                      "credit_note_fs",
                      "credit_note_pp",
                      "codf_reverse",
                      "order_placed",
                      "order_cancellation_fee",
                      "order_refund",
                      "management_fee",
                      "prepaid_remittance",
                      "orderamount_gst_deductions",
                      "payment_gatway_charges",
                      "verified_order_cancellation",

                      "credit_note",
                      "order_reverse_pickup",
                      "prepaid_remittance_reverse",
                      "orderamount_gst_deductions_reverse",
                      "management_fee_reverse",
                      "platform_fee_fixed",
                      "credit_note_platform_fee_fixed",
                      "platform_fee_percentage",
                      "credit_note_platform_fee_percentage",
                    ].indexOf(record.transaction_subtype) !== -1
                  ? record.transaction_data.vforderid
                  : [
                      "cod_remit",
                      "cod_remit_new",
                      "rto_remit",
                      "buyback_handling_fee",
                      "rto_handling_fee",
                      "rto_shipping",
                      "orderamount_gst_deductions",
                      "cod_remit_reverse",
                      "cod_remit_new_reverse",
                      "platform_fee_percentage_reverse",
                    ].indexOf(record.transaction_subtype) !== -1
                  ? record.transaction_data.awb
                  : ["client_sourcing_request"].indexOf(
                      record.transaction_subtype
                    ) !== -1
                  ? record.transaction_data.client_sourcing_request_vfid
                  : null}{" "}
                <span class="tooltiptext">
                  {record.transaction_subtype === "inventory_rtv"
                    ? record.transaction_data.rtv_allocation_id
                    : [
                        "order_placed_ff",
                        "order_placed_pp",
                        "order_placed_fs",
                        "order_placed_codf",
                        "credit_note_ff",
                        "credit_note_fs",
                        "credit_note_pp",
                        "codf_reverse",
                        "order_placed",
                        "order_cancellation_fee",
                        "order_refund",
                        "management_fee",
                        "prepaid_remittance",
                        "orderamount_gst_deductions",
                        "payment_gatway_charges",
                        "verified_order_cancellation",
                        "credit_note",
                        "order_reverse_pickup",
                        "prepaid_remittance_reverse",
                        "orderamount_gst_deductions_reverse",
                        "management_fee_reverse",
                        "platform_fee_fixed",
                        "credit_note_platform_fee_fixed",
                        "platform_fee_percentage",
                        "credit_note_platform_fee_percentage",
                        "customer_refund_request",
                      ].indexOf(record.transaction_subtype) !== -1
                    ? "VF Order id"
                    : [
                        "cod_remit",
                        "cod_remit_new",
                        "rto_remit",
                        "buyback_handling_fee",
                        "rto_handling_fee",
                        "rto_shipping",
                        "orderamount_gst_deductions",
                        "cod_remit_reverse",
                        "cod_remit_new_reverse",
                        "platform_fee_percentage_reverse",
                      ].indexOf(record.transaction_subtype) !== -1
                    ? "AWB"
                    : ["client_sourcing_request"].indexOf(
                        record.transaction_subtype
                      ) !== -1
                    ? "Sourcing Request ID"
                    : null}
                </span>
              </span>

              {[
                "order_placed_ff",
                "order_placed_pp",
                "order_placed_fs",
                "order_placed_codf",
                "credit_note_ff",
                "credit_note_fs",
                "credit_note_pp",
                "codf_reverse",
                "order_placed",
                "credit_note",
                "cod_remit",
                "cod_remit_new",
                "storage_charges",
                "rto_remit",
                "buyback_handling_fee",
                "rto_handling_fee",
                "rto_shipping",
                // "orderamount_gst_deductions",
              ].indexOf(record.transaction_subtype) !== -1 ? (
                record.transaction_subtype === "storage_charges" ? (
                  <div className="new-ref-cell">
                    <Button
                      type="primary"
                      size="small"
                      className="clickable"
                      onClick={() =>
                        this.setState({
                          storageModal: true,
                          storageData: record,
                        })
                      }
                    >
                      Review
                    </Button>
                  </div>
                ) : (
                  <Button
                    type="primary"
                    size="small"
                    className="clickable"
                    onClick={() => this.toggleReviewModal(record._id["$oid"])}
                  >
                    Review
                  </Button>
                )
              ) : null}
              {/* {record.transaction_subtype === "storage_charges" ? (
                <div style={{ position: "relative", top: "-10px" }}>N/A</div>
              ) : null} */}
              {record.transaction_subtype === "cashback_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "funds_added_admin" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "razorpay_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "stripe_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "coupon_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "withdraw_funds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "admin_note" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "wire_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype ===
              "order_confirmation_management_fee" ? (
                <span>N/A</span>
              ) : null}
            </div>
          );
        },
      },
      {
        title: "Taxable Amt",
        dataIndex: "transaction_data",
        width: "10%",
        className: "transaction-details",
        render: (text, record) => {
          if (
            record.transaction_subtype === "client_sourcing_request_reverse" ||
            record.transaction_subtype === "inward_fees_reverse"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.breakup.amount)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_pp" ||
            record.transaction_subtype === "credit_note_pp" ||
            record.transaction_subtype === "order_confirmation_management_fee"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.base)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_ff" ||
            record.transaction_subtype === "credit_note_ff"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.fulfilment.base)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_fs" ||
            record.transaction_subtype === "credit_note_fs"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.shipping.base)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_codf" ||
            record.transaction_subtype === "codf_reverse"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.cod.base)}
              </span>
            );
          } else if (record.transaction_subtype === "buyback_handling_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.product)}
              </span>
            );
          } else if (record.transaction_subtype === "rto_handling_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.rto_handling.base)}
              </span>
            );
          } else if (record.transaction_subtype === "rto_shipping") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.shipping.base)}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_new") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.amount
                  ? formatNumber(record.transaction_data.amount)
                  : formatNumber(record.transaction_data.total)}
              </span>
            );
          } else if (record.transaction_subtype === "client_sourcing_request") {
            if (record.transaction_data.breakup !== undefined) {
              return (
                <span>
                  {getCurrencySymbol(
                    record.transaction_currency === "USD" ? "USD" : "INR"
                  )}{" "}
                  {formatNumber(record.transaction_data.breakup.amount)}
                </span>
              );
            }
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "management_fee") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "prepaid_remittance") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "payment_gatway_charges") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.costs.shipping.base
                  ? formatNumber(record.transaction_data.costs.shipping.base)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_reverse") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.amount
                  ? formatNumber(record.transaction_data.amount)
                  : formatNumber(record.transaction_data.total)}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_new_reverse") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.amount
                  ? formatNumber(record.transaction_data.amount)
                  : formatNumber(record.transaction_data.total)}
              </span>
            );
          } else if (
            record.transaction_subtype === "prepaid_remittance_reverse"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions_reverse"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "management_fee_reverse") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "inventory_rtv") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (
            record.transaction_subtype === "platform_fee_fixed" ||
            record.transaction_subtype === "credit_note_platform_fee_fixed" ||
            record.transaction_subtype === "platform_fee_percentage" ||
            record.transaction_subtype ===
              "credit_note_platform_fee_percentage" ||
            record.transaction_subtype === "platform_fee_percentage_reverse"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_data.costs.base
                  ? record.transaction_data.costs.base
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "storage_charges") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_data.costs.base
                  ? record.transaction_data.costs.base.toFixed(2)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "inward_fees") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_data.breakup.amount
                  ? record.transaction_data.breakup.amount.toFixed(2)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "wingman_charges") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_data.breakup.base
                  ? record.transaction_data.breakup.base.toFixed(2)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "cashback_addfunds" ||
            record.transaction_subtype === "funds_added_admin" ||
            record.transaction_subtype === "coupon_addfunds" ||
            record.transaction_subtype === "credit_note" ||
            record.transaction_subtype === "withdraw_funds" ||
            record.transaction_subtype === "wire_addfunds"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_amount)}
              </span>
            );
          } else if (
            record.transaction_subtype === "razorpay_addfunds" ||
            record.transaction_subtype === "stripe_addfunds"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}
                {record.transaction_data &&
                record.transaction_data.costs &&
                record.transaction_data.costs.orderamount &&
                record.transaction_data.costs.orderamount.base
                  ? formatNumber(record.transaction_data.costs.orderamount.base)
                  : record.transaction_data.costs.base
                  ? formatNumber(record.transaction_data.costs.base)
                  : "0"}
              </span>
              // <span>

              //   {formatNumber(record.transaction_data.costs.orderamount.base)}
              // </span>
            );
          } else if (record.transaction_subtype === "admin_note") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}
                {formatNumber(record.transaction_data.base)}
              </span>
            );
          }
        },
      },

      {
        title: "GST",
        dataIndex: "transaction_data",
        width: "10%",
        className: "transaction-details",
        render: (text, record) => {
          if (
            record.transaction_subtype === "client_sourcing_request_reverse" ||
            record.transaction_subtype === "inward_fees_reverse"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.breakup.gst)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_pp" ||
            record.transaction_subtype === "credit_note_pp" ||
            record.transaction_subtype === "order_confirmation_management_fee"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.gst)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_ff" ||
            record.transaction_subtype === "credit_note_ff"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.fulfilment.gst)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_fs" ||
            record.transaction_subtype === "credit_note_fs"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.shipping.gst)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_codf" ||
            record.transaction_subtype === "codf_reverse"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.cod.gst)}
              </span>
            );
          } else if (record.transaction_subtype === "buyback_handling_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.gst)}
              </span>
            );
          } else if (record.transaction_subtype === "rto_handling_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.rto_handling.gst)}
              </span>
            );
          } else if (record.transaction_subtype === "rto_shipping") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.shipping.gst)}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_new") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "client_sourcing_request" ||
            record.transaction_subtype === "inward_fees" ||
            record.transaction_subtype === "wingman_charges"
          ) {
            if (record.transaction_data.breakup !== undefined) {
              return (
                <span>
                  {" "}
                  {getCurrencySymbol(
                    record.transaction_currency === "USD" ? "USD" : "INR"
                  )}{" "}
                  {formatNumber(record.transaction_data.breakup.gst)}
                </span>
              );
            }
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "management_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "prepaid_remittance") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "payment_gatway_charges") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.costs.shipping.gst
                  ? formatNumber(record.transaction_data.costs.shipping.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_reverse") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_new_reverse") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "prepaid_remittance_reverse"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions_reverse"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "management_fee_reverse") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "inventory_rtv") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.breakup.gst
                  ? formatNumber(record.transaction_data.breakup.gst)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "platform_fee_fixed" ||
            record.transaction_subtype === "credit_note_platform_fee_fixed" ||
            record.transaction_subtype === "platform_fee_percentage" ||
            record.transaction_subtype ===
              "credit_note_platform_fee_percentage" ||
            record.transaction_subtype === "platform_fee_percentage_reverse"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_data.costs.gst
                  ? record.transaction_data.costs.gst
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "storage_charges") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_data.costs.gst
                  ? record.transaction_data.costs.gst.toFixed(2)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "cashback_addfunds" ||
            record.transaction_subtype === "funds_added_admin" ||
            record.transaction_subtype === "coupon_addfunds" ||
            record.transaction_subtype === "credit_note" ||
            record.transaction_subtype === "withdraw_funds" ||
            record.transaction_subtype === "wire_addfunds"
          ) {
            return <span>0</span>;
          } else if (
            record.transaction_subtype === "razorpay_addfunds" ||
            record.transaction_subtype === "stripe_addfunds"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}
                {record.transaction_data &&
                record.transaction_data.costs &&
                record.transaction_data.costs.orderamount &&
                record.transaction_data.costs.orderamount.gst
                  ? record.transaction_data.costs.orderamount.gst.toFixed(2)
                  : record.transaction_data.costs.gst
                  ? record.transaction_data.costs.gst.toFixed(2)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "admin_note") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}
                {record.transaction_data.gst
                  ? record.transaction_data.gst.toFixed(2)
                  : "0"}
              </span>
            );
          }
        },
      },

      {
        title: "Total Amt",
        dataIndex: "transaction_amount",
        width: "10%",
        render: (text, r) => (
          <span
            className={`${
              r.transaction_status === "pending" ||
              r.transaction_status === "in_processing"
                ? "orange-text"
                : r.transaction_type === "debit" ||
                  r.transaction_status === "declined"
                ? "red-text"
                : "green-text"
            }`}
            style={{
              minWidth: "120px",
              display: "inline-block",
            }}
          >
            {getCurrencySymbol(
              r.transaction_currency === "USD" ? "USD" : "INR"
            )}{" "}
            <span class="tooltip">
              {formatNumber(text)}
              <span class="tooltiptext">
                {r.transaction_status === "declined"
                  ? "Declined"
                  : r.transaction_status === "pending" ||
                    r.transaction_status === "in_processing"
                  ? "Pending"
                  : r.transaction_type === "debit" ||
                    r.transaction_status === "declined"
                  ? " Debit"
                  : "Credit"}{" "}
              </span>
            </span>
          </span>
        ),
      },

      {
        title: "Type",
        dataIndex: "transaction_type",
        width: "8%",

        render: (type) => {
          const types = {
            credit: "Credit",
            debit: "Debit",
          };
          return types[type];
        },
      },

      {
        title: "Status",
        dataIndex: "transaction_status",
        width: "10%",
        render: (status) => {
          const statuses = {
            processed: "Processed",
            pending: "Pending",
            in_processing: "In-Processing",
            failed: "Failed",
            declined: "Declined",
          };
          return statuses[status];
        },
      },

      {
        title: "Remarks",
        dataIndex: "transaction_external_remark",
        width: "20%",
      },

      // {
      //   title: "Effective Balance",
      //   dataIndex: "balance",
      //   width: "15%",
      //   render: text => (
      //     <span className="blue-text">
      //       {getCurrencySymbol("INR")} {formatNumber(text)}
      //     </span>
      //   )
      // },
      // {
      //   title: "Comments",
      //   dataIndex: "transaction_comments",
      //   width: "15%",
      //   render: text => <span className="blue-text">{text}</span>
      // }
    ];

    // for global transactions

    const globalTransactionsColumns = [
      {
        title: "Transaction ID",
        dataIndex: "transaction_date",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <span style={{ color: "#212121", fontWeight: "400" }}>
                {record.transaction_vfid}
              </span>
              <span
                style={{
                  fontSize: "11px",
                  display: "block",
                  color: "#212121",
                  fontWeight: "400",
                }}
              >
                {moment(text).format("DD MMM, YYYY")}
              </span>
            </div>
          );
        },
      },
      {
        title: " Store Name",
        dataIndex: "channel_name",
        width: "10%",
      },

      {
        title: "Transaction Head",
        dataIndex: "transaction_data",
        width: "25%",
        className: "transaction-details",
        render: (text, record) => {
          if (record.transaction_subtype === "order_placed") {
            return <span>{`${text.items.length} product(s) ordered`} </span>;
          } else if (record.transaction_subtype === "funds_added_admin") {
            return <span>Wallet Balance Added</span>;
          } else if (record.transaction_subtype === "razorpay_addfunds") {
            return <span>Wallet Balance Added via Razorpay</span>;
          } else if (record.transaction_subtype === "stripe_addfunds") {
            return <span>Wallet Balance Added via Stripe</span>;
          } else if (record.transaction_subtype === "cashback_addfunds") {
            return <span>Cashback</span>;
          } else if (record.transaction_subtype === "coupon_addfunds") {
            return <span>Wallet Balance Added via Coupon</span>;
          } else if (record.transaction_subtype === "wire_addfunds") {
            return <span>Funds Added via Wire Transfer</span>;
          } else if (record.transaction_subtype === "withdraw_funds") {
            return <span>Wallet Balance Withdrawn</span>;
          } else if (record.transaction_subtype === "client_sourcing_request") {
            return <span>Sourcing Request</span>;
          } else if (record.transaction_subtype === "rto_remit") {
            return <span>RTO Remittance</span>;
          } else if (record.transaction_subtype === "management_fee") {
            return <span>Tax Management Fees</span>;
          } else if (record.transaction_subtype === "payment_gatway_charges") {
            return <span>Payment Gatway Charges</span>;
          } else if (record.transaction_subtype === "prepaid_remittance") {
            return <span>Prepaid Remittance</span>;
          } else if (record.transaction_subtype === "cod_remit") {
            return <span>COD Remittance</span>;
          } else if (record.transaction_subtype === "cod_remit_new") {
            return <span>COD Remittance</span>;
          } else if (
            record.transaction_subtype === "order_confirmation_management_fee"
          ) {
            return <span>Order Management Fee</span>;
          } else if (record.transaction_subtype === "credit_note") {
            return (
              <span>
                Credit Note
                {/*<Button
                  type="primary"
                  size="small"
                  className="review-button"
                  onClick={() => this.toggleReviewModal(record._id["$oid"])}
                >
                  Review
                </Button>*/}
              </span>
            );
          } else if (record.transaction_subtype === "admin_note") {
            return <span>Manual Note</span>;
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return <span>Order Reverse Pickup</span>;
          } else if (
            record.transaction_subtype === "verified_order_cancellation"
          ) {
            return <span>Verified Order Cancellation Fee</span>;
          } else if (record.transaction_subtype === "order_refund") {
            return <span>Order Refund</span>;
          } else if (record.transaction_subtype === "order_placed_pp") {
            return <span>Product Price</span>;
          } else if (record.transaction_subtype === "order_placed_ff") {
            return <span>Fulfilment Fees</span>;
          } else if (record.transaction_subtype === "order_placed_fs") {
            return <span>Forward Shipping</span>;
          } else if (record.transaction_subtype === "order_placed_codf") {
            return <span>COD Fees</span>;
          } else if (record.transaction_subtype === "storage_charges") {
            return <span>Storage Charges</span>;
          } else if (record.transaction_subtype === "rto_shipping") {
            return <span>RTO Shipping</span>;
          } else if (record.transaction_subtype === "rto_handling_fee") {
            return <span>RTO Handling Fee</span>;
          } else if (record.transaction_subtype === "buyback_handling_fee") {
            return <span>BuyBack Handling Fee</span>;
          } else if (record.transaction_subtype === "order_cancellation_fee") {
            return <span>Order Cancellation Fee</span>;
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return <span>Reverse Pick Up </span>;
          } else if (record.transaction_subtype === "codf_reverse") {
            return <span>Reverse COD Fees</span>;
          } else if (record.transaction_subtype === "inward_fees") {
            return <span>Inward Fees</span>;
          } else if (record.transaction_subtype === "credit_note_pp") {
            return <span>Product Price Credit</span>;
          } else if (record.transaction_subtype === "credit_note_ff") {
            return <span>Fullfillment Fee Credit</span>;
          } else if (record.transaction_subtype === "credit_note_fs") {
            return <span>Shipping Charges credit</span>;
          } else if (record.transaction_subtype === "storage_charges") {
            return <span>Storage Changes</span>;
          }
        },
      },

      {
        title: "Reference ID",
        dataIndex: "transaction_data",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <span class="tooltip">
                {[
                  "order_placed_ff",
                  "order_placed_pp",
                  "order_placed_fs",
                  "order_placed_codf",
                  "credit_note_ff",
                  "credit_note_fs",
                  "credit_note_pp",
                  "codf_reverse",
                  "order_placed",
                  "inward_fees",
                  "order_cancellation_fee",
                  "order_refund",
                  "management_fee",
                  "prepaid_remittance",
                  "orderamount_gst_deductions",
                  "payment_gatway_charges",
                  "verified_order_cancellation",
                  "credit_note",
                ].indexOf(record.transaction_subtype) !== -1
                  ? record.transaction_data.vforderid
                  : [
                      "cod_remit",
                      "cod_remit_new",
                      "rto_remit",
                      "buyback_handling_fee",
                      "rto_handling_fee",
                      "rto_shipping",
                      "orderamount_gst_deductions",
                    ].indexOf(record.transaction_subtype) !== -1
                  ? record.transaction_data.awb
                  : ["client_sourcing_request"].indexOf(
                      record.transaction_subtype
                    ) !== -1
                  ? record.transaction_data.client_sourcing_request_vfid
                  : record.transaction_subtype === "inventory_rtv"
                  ? record.transaction_data.rtv_allocation_id
                  : null}{" "}
                <span class="tooltiptext">
                  {record.transaction_subtype === "inventory_rtv"
                    ? record.transaction_data.rtv_allocation_id
                    : [
                        "order_placed_ff",
                        "order_placed_pp",
                        "order_placed_fs",
                        "order_placed_codf",
                        "credit_note_ff",
                        "credit_note_fs",
                        "credit_note_pp",
                        "codf_reverse",
                        "order_placed",
                        "order_cancellation_fee",
                        "order_refund",
                        "management_fee",
                        "prepaid_remittance",
                        "orderamount_gst_deductions",
                        "payment_gatway_charges",
                        "verified_order_cancellation",
                        "credit_note",
                      ].indexOf(record.transaction_subtype) !== -1
                    ? "Vf Order id"
                    : [
                        "cod_remit",
                        "cod_remit_new",
                        "rto_remit",
                        "buyback_handling_fee",
                        "rto_handling_fee",
                        "rto_shipping",
                        "orderamount_gst_deductions",
                      ].indexOf(record.transaction_subtype) !== -1
                    ? "AWB"
                    : ["client_sourcing_request"].indexOf(
                        record.transaction_subtype
                      ) !== -1
                    ? "Sourcing request id"
                    : null}{" "}
                </span>
              </span>

              {[
                "order_placed_ff",
                "order_placed_pp",
                "order_placed_fs",
                "order_placed_codf",
                "credit_note_ff",
                "credit_note_fs",
                "credit_note_pp",
                "codf_reverse",
                "order_placed",
                "credit_note",
                "cod_remit",
                "cod_remit_new",
                "rto_remit",
                "buyback_handling_fee",
                "rto_handling_fee",
                "rto_shipping",

                // "orderamount_gst_deductions",
              ].indexOf(record.transaction_subtype) !== -1 ? (
                <Button
                  type="primary"
                  size="small"
                  className="clickable"
                  onClick={() => this.toggleReviewModal(record._id["$oid"])}
                >
                  Review
                </Button>
              ) : null}
              {record.transaction_subtype === "storage_charges" ? (
                <div>
                  {record.transaction_data.vfprodid}
                  <Button
                    type="primary"
                    size="small"
                    className="clickable"
                    onClick={() =>
                      this.toggleStorageModal(record.transaction_vfid)
                    }
                  >
                    Review
                  </Button>
                </div>
              ) : null}
              {record.transaction_subtype === "cashback_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "funds_added_admin" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "razorpay_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "coupon_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "withdraw_funds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "admin_note" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype === "wire_addfunds" ? (
                <span>N/A</span>
              ) : null}
              {record.transaction_subtype ===
              "order_confirmation_management_fee" ? (
                <span>N/A</span>
              ) : null}
            </div>
          );
        },
      },

      {
        title: "Total Amt",
        dataIndex: "transaction_amount",
        width: "15%",
        render: (text, r) => (
          <span
            className={`${
              r.transaction_status === "pending" ||
              r.transaction_status === "in_processing"
                ? "orange-text"
                : r.transaction_type === "debit" ||
                  r.transaction_status === "declined"
                ? "red-text"
                : "green-text"
            }`}
          >
            {getCurrencySymbol(
              r.transaction_currency === "USD" ? "USD" : "INR"
            )}{" "}
            <span class="tooltip">
              {formatNumber(text)}
              <span class="tooltiptext">
                {r.transaction_status === "pending" ||
                r.transaction_status === "in_processing"
                  ? "Pending"
                  : r.transaction_type === "debit" ||
                    r.transaction_status === "declined"
                  ? " Debit"
                  : "Credit"}{" "}
              </span>
            </span>
          </span>
        ),
      },

      {
        title: "Type",
        dataIndex: "transaction_type",
        width: "10%",
        render: (type) => {
          const types = {
            credit: "Credit",
            debit: "Debit",
          };
          return types[type];
        },
      },

      {
        title: "Status",
        dataIndex: "transaction_status",
        width: "15%",
        render: (status) => {
          const statuses = {
            processed: "Processed",
            pending: "Pending",
            in_processing: "In-Processing",
            failed: "Failed",
            declined: "Declined",
          };
          return statuses[status];
        },
      },

      {
        title: "Remarks",
        dataIndex: "transaction_external_remark",
        width: "20%",
      },
    ];

    return (
      <>
        <p className="content-page-text">Your Transaction Logs</p>
        {this.props.loading.transactions ? (
          <Skeleton active />
        ) : !!this.props.transactions.length ? (
          <div className="wallet-transactions">
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            />

            <Row>
              {
                <Card className="table-card">
                  <div
                    className={
                      this.props.transactions.length === 0
                        ? "transaction-history-length"
                        : "Transaction_History"
                    }
                  >
                    <div className="line" />
                    {this.props.loading.transactions ? (
                      <Skeleton active />
                    ) : (
                      <Table
                        loading={this.props.loading.transactions}
                        rowKey={(record) => record._id["$oid"]}
                        columns={
                          this.props.store.store_geo !== "global"
                            ? transactionsColumns
                            : globalTransactionsColumns
                        }
                        dataSource={this.props.transactions}
                        onChange={(page, _, sorter) =>
                          this.props.goToPage(page, sorter)
                        }
                        scroll={{ x: 1000 }}
                        pagination={false}
                      />
                    )}
                  </div>
                </Card>
              }
            </Row>
          </div>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <NotFoundCard
              mainHeading={"Oops! No Data Found."}
              secondHeading={
                <p>Looks like there are no transactions in this page.</p>
              }
            />
          </div>
        )}
        <MyWalletReviewModal
          transactions={this.props.transactions}
          id={this.state.reviewModal.id}
          visible={this.state.reviewModal.visible}
          storegeo={this.props.store.store_geo}
          handleCancel={() => this.toggleReviewModal()}
        />

        {/* <Modal></Modal> */}
        {this.state.reviewModalstorageCharges.visible ? (
          <ModalStorage
            id={this.state.reviewModalstorageCharges.id}
            transactions={this.props.transactions}
            visible={this.state.reviewModalstorageCharges.visible}
            handleCancel={() => this.toggleStorageModal()}
            storegeo={this.props.store.store_geo}
          />
        ) : null}

        <WireTransferSuccessModal
          hideFooter={true}
          visible={this.state.wireModal.visible}
          id={this.state.wireModal.id}
          toggleSuccessModal={() => this.toggleWireModal()}
        />
        <Pagination
          locale={{ jump_to: "Go to page" }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger
          showQuickJumper
          pageSizeOptions={["10", "25", "50"]}
          onShowSizeChange={(p, d) => this.props.onShowSizeChange(p, d)}
          current={this.props.pagination.page}
          onChange={(a, b) => this.props.goToPage(a, b)}
          total={this.props.pagination.total}
          defaultPageSize={this.props.pagination.per_page_count}
        />

        {this.state.storageModal && (
          <Modal
            visible={this.state.storageModal}
            footer={null}
            width={1000}
            keyboard={true}
            onCancel={() =>
              this.setState({ storageModal: false, storageData: null })
            }
          >
            <div className="modal-data-transtion">
              <div className="heading-modal">Storage Charges</div>
              <div className="id-cointainer bn">
                <span className="heading-vf">
                  {/* VF Product ID : {transactions[index].transaction_data.vfprodid} */}
                </span>
                <span className="transtionid mg">
                  Charge Date :{" "}
                  {moment(
                    this.state.storageData.transaction_data.chargedate
                  ).format("DD ,MMM YYYY | HH:mm")}{" "}
                </span>
                <span className="transtionid">
                  Transaction ID : {this.state.storageData.transaction_vfid}{" "}
                </span>
              </div>

              <div className="divider top-divider" />

              {/* <Table columns={columns} dataSource={SKUlist} pagination={false} /> */}

              <div className="new-storage-charges">
                <div className="heads">
                  <div>Opening Inventory</div>
                  <div>Closing Inventory</div>
                  <div>Grace Inventory</div>
                  <div>Storage Applicable Inventory</div>
                  <div>Storage Charge</div>
                  <div>GST</div>
                  <div>Total</div>
                </div>

                <div className="heads values">
                  <div>
                    {this.state.storageData.transaction_data.opening_inventory}
                  </div>
                  <div>
                    {this.state.storageData.transaction_data.closing_inventory}
                  </div>
                  <div>
                    {this.state.storageData.transaction_data.grace_inventory}
                  </div>
                  <div>
                    {this.state.storageData.transaction_data
                      .storage_applicable_inventory
                      ? this.state.storageData.transaction_data
                          .storage_applicable_inventory
                      : 0}
                  </div>
                  <div>
                    {this.state.storageData.transaction_data.storage_charges
                      ? "₹" +
                        this.state.storageData.transaction_data.storage_charges.toFixed(
                          2
                        )
                      : 0}
                  </div>
                  <div>
                    {this.state.storageData.transaction_data.gst
                      ? "₹" +
                        this.state.storageData.transaction_data.gst.toFixed(2)
                      : 0}
                  </div>
                  <div style={{ color: "red" }}>
                    {this.state.storageData.transaction_data.total
                      ? "₹" +
                        this.state.storageData.transaction_data.total.toFixed(2)
                      : 0}
                  </div>

                  {/* {this.state.storageData.transaction_data.costs &&
                  !!this.state.storageData.transaction_data.costs.credits ? (
                    <div>
                      ₹ {this.state.storageData.transaction_data.costs.credits}
                    </div>
                  ) : null}
                  <div>
                    ₹{" "}
                    {this.state.storageData.transaction_data.costs.base.toFixed(
                      2
                    )}
                  </div>
                  <div>
                    ₹{" "}
                    {this.state.storageData.transaction_data.costs.gst.toFixed(
                      2
                    )}
                  </div>
                  <div style={{ color: "red" }}>
                    ₹{" "}
                    {this.state.storageData.transaction_data.costs.total.toFixed(
                      2
                    )}
                  </div> */}
                </div>
              </div>

              <div className="footer-cointainer">
                <div className="divider" />
                <Button
                  onClick={() => {
                    this.setState({ storageModal: false, storageData: null });
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}
//f
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(Transactions);
