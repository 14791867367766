import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

class SourcingProductTitle extends React.Component {
  render() {
    return (
      <div>
        {this.props.productName ? (
          <span>
            {this.props.productName.length <= 60 ? (
              <Link
                to={""}
                onClick={() =>
                  window.location.assign(
                    `${process.env.REACT_APP_NEW_APP}` +
                      "/productDetails/" +
                      this.props.vfprodid
                  )
                }
                target="_blank"
              >
                {this.props.productName}
              </Link>
            ) : (
              <Tooltip title={this.props.productName}>
                {" "}
                <Link
                  to={""}
                  onClick={() =>
                    window.location.assign(
                      `${process.env.REACT_APP_NEW_APP}` +
                        "/productDetails/" +
                        this.props.vfprodid
                    )
                  }
                  target="_blank"
                >
                  {this.props.productName
                    ? `${this.props.productName.substring(0, 60)}...`
                    : null}
                </Link>
              </Tooltip>
            )}
          </span>
        ) : (
          "Product name not available"
        )}
      </div>
    );
  }
}

export default SourcingProductTitle;
